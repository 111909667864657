import React, { useContext, useState } from 'react';
import { ArticleContext } from './ArticleContext';
import {
  AddButton,
  AddCategory as AddAuthor,
  CategoryItem as AuthorItem,
  AuthorList,
  DeleteButton,
  Row,
  Select,
  CategoryList as List,
} from '../../Categories/CategoryManager/Style';
import { AuthContext } from '../../../../../../app/context/AuthContext';
import { AuthorsContext } from '../../../../../../app/context/AuthorsContext';
import { Col } from '../../../../../../components/layouts/Col';
import { GridRow } from '../../../../../../components/layouts/GridRow';

export const AuthorManager = () => {
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';
  const { authorInfo, allAuthorsOptions, assignAuthorToArticle, removeAuthorFromArticle } = useContext(AuthorsContext);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [error, setError] = useState(null);

  const handleSelectAuthor = (selectedOption) => {
    setSelectedAuthor(selectedOption);
  };

  const handleSelectLanguage = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    setError(null); // clear error when language is selected
  };

  const handleAddAuthor = () => {
    if (selectedAuthor && selectedLanguage) {
      assignAuthorToArticle(selectedAuthor, selectedLanguage.value); // Assign author based on chosen language
      setSelectedAuthor(null);
      setSelectedLanguage(null);
    } else {
      setError('Please select a language for the author.'); // Error if language is not selected
    }
  };

  const handleDeleteAuthor = (language) => {
    removeAuthorFromArticle(language); // Remove author based on provided language
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        {['fr', 'en'].map((lang, index) => (
          <div key={index}>
            <small>
              <b>{lang === 'en' ? 'Auteur anglais' : 'Auteur français'}</b>
            </small>
            <List>
              {authorInfo[lang] && (
                <GridRow col="2" colTemplate="6fr 1fr" gridGap="0rem" margin=" 0 0rem">
                  <AuthorItem>{authorInfo[lang].name}</AuthorItem>
                  <DeleteButton onClick={() => handleDeleteAuthor(lang)}>
                    <i className="fa-duotone fa-minus-circle"></i>
                  </DeleteButton>
                </GridRow>
              )}
            </List>
          </div>
        ))}
      </div>
      <AddAuthor style={{ display: 'flex', alignItems: 'center' }}>
        <Col margin="0">
          <Select
            value={selectedAuthor}
            options={allAuthorsOptions.map((a) => ({ value: a.id, label: a.name }))}
            isClearable
            isSearchable
            onChange={handleSelectAuthor}
            placeholder={appLocale === 'en' ? 'Select an author' : 'Sélectionner un auteur'}
            menuPlacement="auto"
          />
          <br />
          <Select
            value={selectedLanguage}
            options={[
              { value: 'en', label: 'Anglais' },
              { value: 'fr', label: 'Français' },
            ]}
            isClearable
            onChange={handleSelectLanguage}
            placeholder="Sélectionner une langue"
            menuPlacement="auto"
          />
        </Col>
        <AddButton selectedAuthor={selectedAuthor}>
          <i className="fa-duotone fa-circle-plus" onClick={handleAddAuthor} />
        </AddButton>
      </AddAuthor>
      {error && <div style={{ color: 'red' }}>{error}</div>} {/* Display error message if error exists */}
    </div>
  );
};
