import { useEffect, useState } from 'react';
import MediaApiHttpClient from '../../../utils/api/media-api-http-client';

export const useImageUpload = (initialImage) => {
  const [imageUrl, setImageUrl] = useState(initialImage);
  const [isUploading, setIsUploading] = useState(false);

  const apiClient = new MediaApiHttpClient();

  const handleImageUpload = async (image, callback) => {
    setIsUploading(true);

    let config = {
      baseURL: `${process.env.REACT_APP_API_URL}/media`,
    };

    try {
      const formData = new FormData();
      formData.append('file', image);

      const response = await apiClient.post('/upload', formData, config);
      // const data = await response.json()
      setImageUrl(config.baseURL + '/' + response.data.fileName);
      // callback(data.url)
      setIsUploading(false);
    } catch (error) {
      console.log(error);
      setIsUploading(false);
    }
  };

  useEffect(() => {
    setImageUrl(initialImage);
  }, [initialImage]);

  return { imageUrl, isUploading, handleImageUpload };
};
