import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';

import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import AttachesTool from '@editorjs/attaches';
import { BLOGCTATOOL } from './BlogCta';
import { NewsletterCTA } from './newsletterCTA';
import ImageTool from '@editorjs/image';
import Signature from './Signature';
import CustomTable from './CustomTable';
class PasteOverrideHeader extends Header {
  get levels() {
    return super.levels.filter((l) => [1, 2, 3, 4].includes(l.number));
  }

  get defaultLevel() {
    return this.levels[1];
  }

  onPaste(event) {
    this.data = { text: event.detail.data.innerText };
  }
}

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: {
    class: CustomTable,
    inlineToolbar: true,
  },
  marker: Marker,
  list: {
    class: List,
    inlineToolbar: true,
  },
  warning: Warning,
  // code: Code,
  linkTool: LinkTool,
  image: {
    class: ImageTool,
    config: {
      field: 'file',
      endpoints: {
        byFile: `${process.env.REACT_APP_API_URL}/media/upload/editorjs`,
      },
    },
  },
  raw: Raw,
  header: PasteOverrideHeader,
  quote: Quote,
  checklist: CheckList,
  delimiter: Delimiter,
  signature: {
    class: Signature,
  },
  inlineCode: InlineCode,
  attaches: {
    class: AttachesTool,
    config: {
      endpoint: `${process.env.REACT_APP_API_URL}/media/upload/editorjs`,
    },
  },
  // blogCTA: BLOGCTATOOL,
  // newsletterCTA: NewsletterCTA,
  // blogCTA: {
  //   class: BLOG_CTA_TOOL.class,
  //   config: BLOG_CTA_TOOL.config,
  //   toolbox: BLOG_CTA_TOOL.toolbox,
  // },
  // newsletterCTA: NEWSLETTER_CTA_TOOL,
};
