import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Select, Selected } from './Style';
import { AuthContext } from '../../../../../../app/context/AuthContext';
import { servicesApi } from '../../../../../../utils/api';
import { useArticleData } from '../../../../../../components/hooks/article/useArticleData';

export const ServiceManager = () => {
  const { id } = useParams();
  const { appLocale } = useContext(AuthContext)?.user || {};

  const [selectedServiceOption, setSelectedServiceOption] = useState(null);
  const [servicesOptions, setServicesOptions] = useState([]);
  const { assignServiceToArticle, article } = useArticleData(id);

  useEffect(() => {
    servicesApi
      .get('/services')
      .then((response) => {
        const optionsResult = response.data.map((service) => ({
          value: service.id,
          label: service.name,
        }));
        setServicesOptions(optionsResult);
        const selectedServiceOption = optionsResult.find((option) => option.value == article.service);
        setSelectedServiceOption(selectedServiceOption);
      })
      .catch((error) => {
        // Handle the error appropriately, e.g., set an error state or log it
        console.error('Error fetching services:', error);
      });
  }, [article]);

  return (
    <>
      {' '}
      {selectedServiceOption && (
        <div></div>
        // <Selected >
        //   <span>{selectedServiceOption.label}</span>
        // </Selected >
      )}
      <Select
        value={selectedServiceOption}
        options={servicesOptions}
        isClearable
        isSearchable
        onChange={(selectedOption) => {
          const sv = selectedOption ? selectedOption.value : null;
          assignServiceToArticle(sv);
          setSelectedServiceOption(selectedOption);
        }} // make setter api call here
        placeholder={appLocale === 'en' ? 'Select a service' : 'Sélectionner un service'}
      />
    </>
  );
};
