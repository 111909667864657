import React, { useState, useEffect } from 'react';
import authApi from '../../api/authApi';
import { AuthContext } from '../../../app/context/AuthContext';
import { AppMainLayout } from '../../../components/layouts/AppMainLayout';
import setIdToken from '../../auth/setIdToken';
import setAuthToken from '../../auth/setAuthToken';

function PrivateRoute2({ children }) {
  const [authStatus, setAuthStatus] = useState('checking');
  const [user, setUser] = useState(null);

  const logout = async () => {
    try {
      const frontendId = process.env.REACT_APP_FRONTEND_ID;
      setUser(null);

      const idToken = localStorage.getItem('idToken');
      setIdToken(null);
      setAuthToken(null);

      window.location.replace(`${process.env.REACT_APP_API_URL}/logout/${frontendId}?id_token=${idToken}`);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    const frontendId = process.env.REACT_APP_FRONTEND_ID;
    authApi
      .get(`/auth/status/${frontendId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        },
      })
      .then((response) => {
        const { isAuthenticated, user } = response.data;
        if (isAuthenticated && user) {
          setAuthStatus('authenticated');
          setUser(user);
        } else {
          setAuthStatus('unauthenticated');
          setUser(null);
        }
      })
      .catch((error) => {
        console.error('Error checking auth status:', error);
        setAuthStatus('error');
      });
  }, []); // The empty dependency array means this useEffect runs once when the component mounts

  switch (authStatus) {
    case 'checking':
      return <div>Authenticating...</div>;
    case 'authenticated':
      return (
        <AuthContext.Provider value={{ user: { ...user, appLocale: 'fr' }, logout }}>
          <AppMainLayout isLoggedIn>{children}</AppMainLayout>
        </AuthContext.Provider>
      );
    case 'unauthenticated':
      const frontendId = process.env.REACT_APP_FRONTEND_ID;
      window.location.replace(`${process.env.REACT_APP_API_URL}/login/${frontendId}`);
      return null;
    case 'error':
      return <div>There was an error checking your authentication status.</div>;
    default:
      return null;
  }
}

export default PrivateRoute2;
