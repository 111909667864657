import { useEffect, useState } from 'react';

export const useForm = (initialValues, onSubmit = () => {}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = onSubmit(values);
    if (validationErrors) {
      setErrors(validationErrors);
    }
  };

  return { values, setValues, handleChange, handleSubmit, errors };
};
