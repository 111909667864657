import React, { useState } from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 3rem;
  cursor: pointer;
  margin: auto;
  /* padding: 0 4rem; */
  transform: translateY(-1.4rem);
`;

const Option = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  width: 50%;
  height: 100%;
  color: ${({ theme, active }) => (active ? theme.colors.primary : '#E1E1E1')};
  font-weight: ${({ active }) => (active ? 'bold' : '500')};
  border-bottom: 0.3rem solid;
  border-bottom-color: ${({ theme, active }) => (active ? theme.colors.primary : '#f1f1f1')};
  /* border-radius: ${({ active }) => (active ? '10rem 0 0 10rem' : '0 10rem 10rem 0')}; */
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
`;

const Toggle = ({ options, panelView, setPanelView }) => {
  return (
    <ToggleWrapper>
      <Option active={panelView === 0} onClick={() => setPanelView(0)}>
        {options[0]}
      </Option>
      <Option active={panelView === 1} onClick={() => setPanelView(1)}>
        {options[1]}
      </Option>
      {/*<Option active={panelView === 2} onClick={() => setPanelView(2)}>*/}
      {/*  {options[2]}*/}
      {/*</Option>*/}
    </ToggleWrapper>
  );
};

export default Toggle;
