export const SortControl = ({ label, direction, active, onClick }) => {
  const handleClick = () => {
    onClick(direction === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div onClick={handleClick}>
      <span>{label}</span>
      {active && <span>{direction === 'asc' ? '▲' : '▼'}</span>}
    </div>
  );
};
