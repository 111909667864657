import { useState, Fragment, useContext } from 'react';

import { H4, Label, StyledTable, TableBody, TableCell, TableHead, TableHeading, TableRow, TextField } from './Style';
import Button from '../../../components/elements/Button';
import { Card } from '../../../components/layouts/Card';
import { Row } from '../../../components/layouts/Row';
import { Col } from '../../../components/layouts/Col';
import { TableActionButton } from '../../../components/layouts/TableUpdated/TableActionButton';
import { RightPanelModal } from '../../../components/layouts/RightPanelModal';
import { Pagination } from '../../../components/layouts/Pagination';
import { H3 } from '../../../components/elements/H3';
import { Error } from '../../../components/elements/Error';

import { useCategories } from './useCategories';
import { usePagination } from '../../../components/hooks/usePagination';
import { useCategoriesData, useCategoriesStore } from '../../articleManagement/context/CategoriesProvider';
import { AuthContext } from '../../../app/context/AuthContext';

const interfaceTexts = {
  en: {
    pageTitle: 'Categories',
    addButton: 'Add Category',
    createNewCategory: 'Create new category',
    createCategoryPlaceholder: 'Category name',
    saveButton: 'Save',
    errorNewCategory: 'Add categories translations to save',
  },
  fr: {
    pageTitle: 'Catégories',
    addButton: 'Ajouter une Catégorie',
    createNewCategory: 'Créer une nouvelle catégorie',
    createCategoryPlaceholder: 'Nom de la catégorie',
    saveButton: 'Enregistrer',
    errorNewCategory: 'Ajouter les traductions de la catégories pour sauvegarder',
  },
};

export const CategoryTable = () => {
  // const [allCategoriesOptions, setAllCategoriesOptions] = useCategoriesStore((store) => store['allCategoriesOptions'])
  // const { categories, setCategories } = categoryInfo

  const {
    categories,
    setCategories,
    store,
    updateCategoryStore,
    getAllCategories,
    createCategory,
    updateCategory,
    deleteCategory,
  } = useCategories();
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';
  const [editingId, setEditingId] = useState(null);

  const [addMode, setAddMode] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [appTranslations, setAppTranslations] = useState(['en', 'fr']); // mock received translations
  const [name, setName] = useState(appTranslations.map((locale) => ({ title: '', locale })));
  const [errorNewCategory, setErrorNewCategory] = useState('');

  const handleCategoryChange = (id, locale, value) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === id) {
        const updatedTranslations = category.translations.map((translation) => {
          if (translation.locale === locale) {
            return {
              ...translation,
              title: value,
            };
          }
          return translation;
        });

        return {
          ...category,
          translations: updatedTranslations,
        };
      }
      return category;
    });
    setCategories(updatedCategories);
  };

  const handleSaveCategory = async (category) => {
    const { editMode, ...categoryWithoutEditMode } = category;

    await updateCategory(categoryWithoutEditMode);
    setEditingId(null);
  };

  const handleCancelEdit = (category) => {
    const updatedCategories = categories.map((c) => {
      if (c.id === category.id) {
        return { ...c, editMode: false };
      }
      return c;
    });
    setCategories(updatedCategories);
  };

  const handleEditCategory = (category) => {
    const updatedCategories = categories.map((c) => {
      if (c.id === category.id) {
        return { ...c, editMode: true };
      }
      return { ...c, editMode: false };
    });
    setCategories(updatedCategories);
  };

  const handleDeleteCategory = async (category) => {
    await deleteCategory(category.id);
  };

  const handleCreateCategory = async () => {
    const categoryData = appTranslations.map((translation, index) => ({
      locale: translation,
      title: name[index].title,
    }));
    console.log(categoryData);
    if (categoryData.some((category) => !category.title)) {
      setErrorNewCategory(interfaceTexts[appLocale]?.errorNewCategory);
    } else {
      setErrorNewCategory(null); // Clear the error if all fields are filled
    }

    await createCategory(categoryData);

    setAddMode(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleCreateCategory();
    }
  };

  const renderCategoryTranslationsInputs = (translations) => {
    if (translations && Array.isArray(translations)) {
      return translations.map((translation, index) => (
        <Fragment key={`${translation.locale}-${index}`}>
          <Label>{translation.locale}</Label>
          <TextField
            key={index}
            type="text"
            placeholder={interfaceTexts[appLocale]?.createCategoryPlaceholder}
            value={translation.title}
            onChange={(e) => handleTranslationChange(index, e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Fragment>
      ));
    }
    return null;
  };

  const handleTranslationChange = (index, value) => {
    const updatedName = [...name];
    updatedName[index] = { ...updatedName[index], title: value };
    setName(updatedName);
  };

  const { displayedItems, totalPages, currentPage, handlePageChange } = usePagination(categories, 5);

  return (
    <Card>
      <Row align="center" justify="space-between" width="100%" margin="0">
        <H3>{interfaceTexts[appLocale]?.pageTitle}</H3>
        {!addMode && (
          <Button
            marginLeft="auto"
            onClick={() => {
              setAddMode(true);
              setViewModal(true);
            }}
          >
            {interfaceTexts[appLocale]?.addButton}
          </Button>
        )}
        {addMode && (
          <RightPanelModal
            shouldShow={viewModal}
            onRequestClose={() => {
              setViewModal(false);
              setAddMode(false);
            }}
          >
            <Col justify="flex-start" height="auto">
              <H4>{interfaceTexts[appLocale]?.createNewCategory}</H4>

              {renderCategoryTranslationsInputs(name)}

              {errorNewCategory && <Error>{errorNewCategory}</Error>}
              <Button onClick={handleCreateCategory} marginTop="1rem">
                {interfaceTexts[appLocale]?.saveButton}
                <TableActionButton iconClassName="fa-solid fa-check-circle" color="white" />
              </Button>
            </Col>
          </RightPanelModal>
        )}
      </Row>
      <StyledTable>
        <TableHead>
          <tr>
            {/* {appTranslations.map((translation) => {
              return <TableHeading>{translation?.toLocaleUpperCase()}</TableHeading>
            })} */}
            <TableHeading>EN</TableHeading>
            <TableHeading>FR</TableHeading>
            {appTranslations.map((translation) => {
              if (
                translation !== 'en' &&
                translation !== 'fr' &&
                categories.some((category) => category.translations.some((t) => t.locale === translation))
              ) {
                return <TableHeading>{translation.toLocaleUpperCase()}</TableHeading>;
              }

              return null;
            })}
            <TableHeading>Actions</TableHeading>
          </tr>
        </TableHead>
        <TableBody>
          {displayedItems.map((category) => {
            // default behaviour: show 'en' first, then 'fr', then whatever's left if translation is found in appTranslations AND displayedItems
            const otherTranslationsToShow = appTranslations.filter(
              (locale) => locale !== 'en' && locale !== 'fr' && category.translations.some((t) => t.locale === locale),
            );
            return (
              <TableRow key={category.id}>
                {/* Display 'en' translation */}
                <TableCell style={{ textAlign: 'left', paddingLeft: '3rem' }}>
                  {category.editMode ? (
                    <input
                      type="text"
                      value={category.translations.find((t) => t.locale === 'en')?.title || ''}
                      onChange={(e) => handleCategoryChange(category.id, 'en', e.target.value)}
                    />
                  ) : (
                    category.translations.find((t) => t.locale === 'en')?.title || ''
                  )}
                </TableCell>

                {/* Display 'fr' translation */}
                <TableCell style={{ textAlign: 'left', paddingLeft: '3rem' }}>
                  {category.editMode ? (
                    <input
                      type="text"
                      value={category.translations.find((t) => t.locale === 'fr')?.title || ''}
                      onChange={(e) => handleCategoryChange(category.id, 'fr', e.target.value)}
                    />
                  ) : (
                    category.translations.find((t) => t.locale === 'fr')?.title || ''
                  )}
                </TableCell>

                {/* Display other translations */}
                {otherTranslationsToShow.map((locale) => {
                  const translation = category.translations.find((t) => t.locale === locale);
                  return (
                    <TableCell style={{ textAlign: 'left', paddingLeft: '3rem' }}>
                      {category.editMode ? (
                        <input
                          type="text"
                          value={translation ? translation.title : ''}
                          onChange={(e) => handleCategoryChange(category.id, locale, e.target.value)}
                        />
                      ) : translation.title ? (
                        translation.title
                      ) : (
                        ''
                      )}
                    </TableCell>
                  );
                })}

                <TableCell>
                  {category.editMode ? (
                    <>
                      <TableActionButton
                        iconClassName="fa-solid fa-check-circle"
                        onClick={() => handleSaveCategory(category)}
                      />
                      <TableActionButton iconClassName="fa-solid fa-xmark" onClick={() => handleCancelEdit(category)} />
                    </>
                  ) : (
                    <>
                      <TableActionButton
                        iconClassName="fa-solid fa-pen-to-square"
                        onClick={() => handleEditCategory(category)}
                      />
                      <TableActionButton
                        iconClassName="fa-solid fa-trash"
                        onClick={() => handleDeleteCategory(category)}
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>
      <Pagination pageCount={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </Card>
  );
};
