import { useState, useEffect } from 'react';

export const usePagination = (items, limit) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [displayedItems, setDisplayedItems] = useState([]);

  useEffect(() => {
    setTotalPages(Math.ceil(items?.length / limit));
  }, [items, limit]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * limit;
    const endIndex = startIndex + limit;
    const itemsToDisplay = items?.slice(startIndex, endIndex);
    setDisplayedItems(itemsToDisplay);
  }, [currentPage, items, limit]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return {
    displayedItems,
    totalPages,
    currentPage,
    handlePageChange,
    setCurrentPage,
  };
};
