import { useContext, useState } from 'react';
import {
  ChevronLeftIcon,
  Divider,
  Drawer,
  DrawerHeader,
  H2,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemLabel,
  ListItemText,
  SideBarWrapper,
} from './Style';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Image } from '../../elements/Image';
import { AuthContext } from '../../../app/context/AuthContext';
import { interfaceTexts } from './interfaceTexts';
import useAuthentication from '../../hooks/useAuthentication';
import Button from '../../elements/Button';

export function SideBarNav({ open, setOpen }) {
  const { user, logout } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  const location = useLocation();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#0f3e8e"
      version="1.0"
      id="Layer_1"
      width="80%"
      height="80%"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
    >
      <g>
        <path d="M34,31h29c0.553,0,1-0.447,1-1C64,13.432,50.568,0,34,0c-0.553,0-1,0.447-1,1v29C33,30.553,33.447,31,34,31z" />
        <path d="M63,33H36c-0.044,0-0.082,0.019-0.125,0.024c-0.084,0.011-0.168,0.019-0.248,0.05c-0.078,0.031-0.143,0.084-0.209,0.133   c-0.036,0.027-0.079,0.041-0.112,0.072c-0.002,0.002-0.003,0.006-0.005,0.008c-0.086,0.084-0.152,0.185-0.203,0.295   c-0.004,0.009-0.014,0.016-0.018,0.025c-0.016,0.038-0.015,0.084-0.026,0.125c-0.023,0.084-0.051,0.169-0.052,0.256L35,34   c0,0.053,0.022,0.1,0.031,0.152c0.012,0.074,0.016,0.148,0.044,0.219c0.035,0.088,0.092,0.16,0.149,0.233   c0.021,0.028,0.031,0.063,0.057,0.089l0.01,0.01c0,0,0.002,0.003,0.004,0.004l19.918,20.506C60.643,49.784,64,42.284,64,34l0,0   C64,33.447,63.553,33,63,33z" />
        <path d="M31.615,34.291C31.012,33.688,31,32.605,31,32.605V5c0-0.553-0.447-1-1-1C13.432,4,0,17.432,0,34s13.432,30,30,30   c8.284,0,15.784-3.357,21.213-8.787C51.213,55.213,32.596,35.271,31.615,34.291z" />
      </g>
    </svg>
  );

  const icon2 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75%"
      height="75%"
      viewBox="0 0 24 24"
      id="create-note-alt"
      className="icon glyph"
      style={{ margin: 'auto' }}
    >
      <path
        d="M20,22H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2h8a1,1,0,0,1,0,2H4V20H20V12a1,1,0,0,1,2,0v8A2,2,0,0,1,20,22Z"
        style={{ fill: '#0f3e8e' }}
      />
      <path
        d="M22,1.94a1,1,0,0,0-.87-.9,9.45,9.45,0,0,0-2.83.17,1,1,0,0,0-.76.72l-.18.72-.83-.33a1,1,0,0,0-1,.13,4.87,4.87,0,0,0-.7.67C13,5.15,13,8.36,13,9.58l-1.72,1.71a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L14.42,11h.36a7.2,7.2,0,0,0,5.41-2.12h0C22.37,6.39,22,2.12,22,1.94Z"
        style={{ fill: '#0f3e8e' }}
      />
    </svg>
  );

  const icon3 = (
    <svg xmlns="http://www.w3.org/2000/svg" width="75%" height="75%" viewBox="0 0 1024 1024" id="statistics">
      <path
        style={{ fill: '#0f3e8e' }}
        d="M458.2 391.1v457.1c0 6.4-5.2 11.6-11.6 11.6H335.8c-6.4 0-11.6-5.2-11.6-11.6V391.1c0-6.4 5.2-11.6 11.6-11.6h110.9c6.4.1 11.5 5.2 11.5 11.6z"
      ></path>
      <path
        style={{ fill: '#0f3e8e' }}
        d="M448.2 391.1v450.3c0 2 .5 4.7 0 6.7-.3 1.4-.7 1.5-2 1.7-2.3.4-5.1 0-7.3 0h-34.4c-22.3 0-44.7.9-67 0-2.7-.1-3.2-.3-3.3-2.9-.2-3.2 0-6.6 0-9.8V393.5c0-2.3-.3-3.5 2.1-3.8 7.3-.9 15.2 0 22.5 0h76.6c3.1 0 6.3.1 9.4 0 1.7-.2 3.3-.4 3.4 1.4.7 12.8 20.7 12.9 20 0-.7-12.1-9.4-21.1-21.6-21.6-2-.1-4.1 0-6.2 0h-33.5c-23.2 0-46.4-.2-69.5 0-12.8.1-22.8 8.7-23.2 21.9-.1 2.7 0 5.3 0 8v359c0 29.5-.4 59 0 88.5.2 12.5 8.8 22.3 21.7 22.8 6.8.3 13.7 0 20.5 0h78.4c9.5 0 19.7 1.1 27.2-6.3 5.1-5.1 6.3-11.6 6.3-18.4V391.2c-.1-12.9-20.1-13-20.1-.1zm251.6-215.3v672.4c0 6.4-5.2 11.6-11.6 11.6H577.3c-6.4 0-11.6-5.2-11.6-11.6V175.8c0-6.4 5.2-11.6 11.6-11.6h110.9c6.4 0 11.6 5.2 11.6 11.6z"
      ></path>
      <path
        style={{ fill: '#0f3e8e' }}
        d="M689.8 175.8V836.8c0 3.5.3 7.2 0 10.7-.1 1.4-.1 1.9-1.6 2.3-2.9.7-6.8 0-9.7 0h-96.7c-3.9 0-5.9.6-5.9-3.3-.1-4.2 0-8.5 0-12.7V180.2c0-2.6-.6-5.4 1.6-6 3.1-.7 7.2 0 10.3 0h95.9c2.1 0 5.9-.7 6.1 1.6.7 12.8 20.7 12.9 20 0-.7-12.7-10.1-21.4-22.7-21.6-4.8-.1-9.7 0-14.5 0h-55c-13.1 0-26.2-.2-39.2 0-13.5.2-22.4 10.3-22.5 23.4-.1 12.6 0 25.2 0 37.9V809.4c0 12.4-.1 24.9 0 37.3.1 13.3 9.4 23 22.8 23.1 13.6.2 27.2 0 40.8 0H674c4.5 0 9 .1 13.5 0 12.3-.2 21.8-9.1 22.3-21.6.3-8.8 0-17.7 0-26.5V175.8c0-12.9-20-12.9-20 0zM941.3 279.6v568.6c0 6.4-5.2 11.6-11.6 11.6H818.9c-6.4 0-11.6-5.2-11.6-11.6V279.6c0-6.4 5.2-11.6 11.6-11.6h110.9c6.3 0 11.5 5.2 11.5 11.6z"
      ></path>
      <path
        style={{ fill: '#0f3e8e' }}
        d="M931.3 279.6V843.8c0 2.5.6 5.4-1.6 6-1.6.4-4.4-.1-6.2-.1H821.9c-3.3 0-4.5.1-4.6-3.1-.1-3.8 0-7.6 0-11.3V290.1c0-3.2-.3-6.5 0-9.7.1-1.4.1-1.9 1.6-2.3 1.9-.5 4.8 0 6.7 0h101c2-.1 4.6-.6 4.7 1.5.7 12.8 20.7 12.9 20 0-.7-12.3-9.6-21.2-22-21.6-3.4-.1-6.8 0-10.2 0h-89.6c-3.4 0-6.8-.1-10.2 0-12.2.4-21.5 9.2-22 21.6-.3 7.7 0 15.4 0 23.1V813.4c0 11.1-.1 22.3 0 33.4.1 12.8 9 22.6 22.1 23 10.2.3 20.5 0 30.8 0 26.4 0 52.9.9 79.3 0 13.1-.5 21.8-10.4 21.9-23.2V279.6c-.1-12.9-20.1-12.9-20.1 0zM216.7 506.6v341.6c0 6.4-5.2 11.6-11.6 11.6H94.3c-6.4 0-11.6-5.2-11.6-11.6V506.6c0-6.4 5.2-11.6 11.6-11.6h110.9c6.3.1 11.5 5.2 11.5 11.6z"
      ></path>
      <path
        style={{ fill: '#0f3e8e' }}
        d="M206.7 506.6v336.6c0 1.5.4 3.6 0 5-.6 2.4-4.5 1.6-7.3 1.6H112.1c-5.6 0-11.5.5-17.1 0-2.1-.2-2.2-.7-2.4-2.7-.2-2.8 0-5.7 0-8.5V517.3c0-3-.1-6 0-9 0-2-.1-2.7 2-3.1 4.9-.9 10.7 0 15.6 0h88.1c2.5-.1 8.2-1.1 8.4 1.4.7 12.8 20.7 12.9 20 0-.8-13.6-11-21.6-24.1-21.6h-87.8c-6.4 0-12.8-.1-19.2 0-12.6.1-22.4 8.8-22.9 21.6-.3 6.4 0 12.8 0 19.2v245.5c0 25.2-.3 50.5 0 75.7.1 10.6 6.7 20.2 17.6 22.2 5 .9 10.3.4 15.3.4H196.9c6.8 0 13.4.5 19.5-3.2 11.4-6.8 10.3-19 10.3-30.1V506.4c0-12.6-20-12.7-20 .2z"
      ></path>
    </svg>
  );
  const icon4 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#0f3e8e"
      width="75%"
      height="75%"
      viewBox="0 0 512 512"
      id="_x30_1"
      version="1.1"
      xmlSpace="preserve"
    >
      <path d="M496.851,212.213l-48.806-12.201c-4.106-14.108-9.722-27.572-16.666-40.205l25.89-43.151  c4.722-7.869,3.482-17.943-3.008-24.432l-34.485-34.485c-6.489-6.49-16.563-7.729-24.432-3.008l-43.151,25.89  c-12.633-6.944-26.097-12.56-40.205-16.666l-12.201-48.805C297.561,6.246,289.562,0,280.384,0h-48.769  c-9.177,0-17.177,6.246-19.403,15.149l-12.201,48.805c-14.108,4.106-27.572,9.722-40.205,16.666l-43.151-25.89  c-7.87-4.722-17.943-3.482-24.432,3.008L57.738,92.223c-6.489,6.489-7.729,16.562-3.008,24.432l25.89,43.151  c-6.944,12.633-12.56,26.097-16.666,40.205l-48.806,12.201C6.246,214.438,0,222.438,0,231.615v48.769  c0,9.177,6.246,17.177,15.149,19.403l48.806,12.201c4.106,14.108,9.722,27.572,16.666,40.205l-25.89,43.151  c-4.722,7.869-3.482,17.943,3.008,24.432l34.485,34.485c6.489,6.49,16.563,7.729,24.432,3.008l43.151-25.89  c12.633,6.944,26.097,12.56,40.205,16.666l12.201,48.805c2.226,8.903,10.225,15.149,19.403,15.149h48.769  c9.177,0,17.177-6.246,19.403-15.149l12.201-48.805c14.108-4.106,27.572-9.722,40.205-16.666l43.151,25.89  c7.87,4.722,17.943,3.482,24.432-3.008l34.485-34.485c6.489-6.489,7.729-16.562,3.008-24.432l-25.89-43.151  c6.944-12.633,12.56-26.097,16.666-40.205l48.806-12.201c8.903-2.226,15.149-10.226,15.149-19.403v-48.769  C512,222.438,505.754,214.438,496.851,212.213z M256,336c-44.112,0-80-35.888-80-80s35.888-80,80-80s80,35.888,80,80  S300.112,336,256,336z" />
    </svg>
  );

  return (
    <SideBarWrapper open={open}>
      <Drawer open={open}>
        <DrawerHeader onClick={() => setOpen(!open)}>
          {open ? <H2>Modern Blog Manager</H2> : <Image src="/assets/SR-logo.png" style={{ width: '4rem' }}></Image>}
          {/* <IconButton onClick={handleDrawerClose}>{<ChevronLeftIcon />}</IconButton> */}
        </DrawerHeader>
        {/* <Divider /> */}
        <List>
          {['Dashboard', 'Create Article', 'Statistics', 'Settings'].map((text, index) => (
            <Link
              key={index}
              to={
                index % 4 === 0
                  ? '/dashboard'
                  : index % 4 === 1
                    ? '/create-article'
                    : index % 4 === 2
                      ? '/statistics'
                      : '/settings'
              }
              style={{ width: '100%', cursor: 'pointer' }}
            >
              <ListItem
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
                open={open}
                className={location.pathname === `/${text.toLowerCase().replace(' ', '-')}` ? 'active' : ''}
              >
                <ListItemButton justifyContent={open ? 'initial' : 'center'}>
                  <ListItemIcon>
                    {index % 4 === 0 ? (
                      icon
                    ) : index % 4 === 1 ? (
                      icon2
                    ) : index % 4 === 2 ? (
                      icon3
                    ) : index % 4 === 3 ? (
                      icon4
                    ) : (
                      <i class="fa-solid fa-cog"></i>
                    )}
                  </ListItemIcon>
                  {hoveredIndex === index && (
                    <ListItemLabel>
                      {index % 4 === 0 ? (
                        <span>{interfaceTexts[appLocale]?.dashboard}</span>
                      ) : index % 4 === 1 ? (
                        <span>{interfaceTexts[appLocale]?.createArticle}</span>
                      ) : index % 4 === 2 ? (
                        <span>{interfaceTexts[appLocale]?.statistics}</span>
                      ) : (
                        <span>{interfaceTexts[appLocale]?.settings}</span>
                      )}
                    </ListItemLabel>
                  )}
                  {open && <ListItemText opacity={open ? 1 : 0}>{text}</ListItemText>}
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
          <ListItem>
            <ListItemButton justifyContent={open ? 'initial' : 'center'} onClick={logout}>
              <ListItemIcon>
                <i className="fa-solid fa-sign-out"></i>
              </ListItemIcon>
              {/*<ListItemLabel>{interfaceTexts[appLocale]?.logout}</ListItemLabel>*/}
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </SideBarWrapper>
  );
}
