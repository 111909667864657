import { useState } from 'react';
import { Configuration, OpenAIApi } from 'openai';

export const useOpenAI = (text, isActive) => {
  const [response, setResponse] = useState('');

  if (isActive) {
    const handleClick = async () => {
      const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      });
      const openai = new OpenAIApi(configuration);
      console.log('text', text);
      try {
        const res = await openai.createCompletion({
          model: 'text-davinci-003',
          // prompt: `The following is a conversation with an AI assistant. The assistant is helpful, creative, clever, and very friendly.\n\nHuman: Hello, who are you?\nAI: I am an AI created by OpenAI. How can I help you today?\nHuman: I'd like explore clever variations of a text I'm writing. I'll give you a text, and I want you to give me 5 variations. The first one is a variation way different from the text I'll provide, completely different but better. The second variation is more professional. The third one is more casual. The fourth one is half the words. The last one is in great depth. Write the variations direcly as strings in an array without adding 1,2,3 or Variation 1,2,3,4 or adding anything else before: I just want an array, with strings of plain text, no prefix, nothing, just an array of strings. Also, just directly write the content for each variation, do not right the variation before like you sometimes do "i.e. more professional:". I just want the text for each variation as strings in an array. Keep the language the same as the text I give you. Here's the text "${text}" \nAI:`,
          // prompt: `\n\nHuman: I'd like explore clever variations of this: "${text}". I want you to give me 5 variations - the first one is a variation way different from the text I'll provide, completely different but better. The second variation is more professional. The third one is more casual. The fourth one is half the words. The last one is in great depth. Answer ONLY with an array of strings, each string being a variation, nothing else. \nAI:`,
          prompt: `I'd like to explore clever variations of this: "${text}". I want you to give me 5 variations - the first one is a variation way different from the text I provided, completely different but better. The second variation is more professional. The third one is more casual. The fourth one is half the words. The last one is in great depth. Answer ONLY with an array of strings, each string being a variation, nothing else.`,
          max_tokens: 2000,
          temperature: 0,
        });
        console.log('response', res);

        setResponse(res.data.choices[0].text);
      } catch (err) {
        console.error(err);
      }
    };
    return { response, handleClick };
  } else {
    return {};
  }
};
