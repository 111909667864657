import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/layouts/TableUpdated';
import { Container, H1, Page } from './Style';

import useDebounce from '../../../components/hooks/useDebounce';
import { AuthContext } from '../../../app/context/AuthContext';
import { interfaceTexts } from './interfaceTexts';
import ApiHttpClient from '../../../utils/api/api-http-client';

export const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  const [articles, setArticles] = useState([]);

  const [search, setSearch] = useState(null);
  const debouncedSearch = useDebounce(search, 150);

  const client = new ApiHttpClient();

  const fetchArticles = async (page) => {
    try {
      const endpoint = `/articles/search?page=${page}&limit=15&locale=${appLocale}`;
      const requestBody = {
        term: search,
      };
      const response = await client.post(endpoint, requestBody);
      setArticles(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (appLocale) {
      fetchArticles(1);
    }
  }, [appLocale]);

  async function handlePageChange(page) {
    await fetchArticles(page);
  }

  async function fetchData() {
    if (debouncedSearch || debouncedSearch === '') {
      const requestBody = {
        term: debouncedSearch,
      };
      const response = await client.post(`/articles/search?page=1&limit=15&locale=${appLocale}`, requestBody);
      const data = await response.data;
      setArticles(data);
    }
  }

  useEffect(() => {
    fetchData();
  }, [debouncedSearch]);

  const dashboardColHeaders = [
    { label: interfaceTexts[appLocale]?.articleTitle, sortKey: 'title' },
    { label: interfaceTexts[appLocale]?.publish, sortKey: 'publishStatus' },
    { label: interfaceTexts[appLocale]?.frenchStatus, sortKey: 'frenchStatus' },
    { label: interfaceTexts[appLocale]?.englishStatus, sortKey: 'englishStatus' },
    { label: interfaceTexts[appLocale]?.lastUpdate, sortKey: 'lastUpdate' },
    { label: interfaceTexts[appLocale]?.actions, sortKey: 'action' },
  ];

  return (
    <Page>
      <H1>{interfaceTexts[appLocale]?.dashboard}</H1>
      <Container>
        <Table
          name="dashboardTable"
          lineItems={articles?.items}
          searchBar
          colHeaders={dashboardColHeaders}
          setSearch={setSearch}
          paginationOptions={articles.meta}
          onPageChange={handlePageChange}
        />
      </Container>
    </Page>
  );
};
