import { useEffect } from 'react';
import createHighVelocityContext from '../../../../utils/createHighVelocityContext';
import { useState } from 'react';
import ApiHttpClient from '../../../../utils/api/api-http-client';

export const { Provider, useStore } = createHighVelocityContext({
  article: {},
});

export const useArticleData = (id) => {
  const [articleStore, updateArticleStore] = useStore((store) => store);
  const [loading, setLoading] = useState(false);
  const apiClient = new ApiHttpClient();

  const getArticle = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/articles/${id}`);
      updateArticleStore({ ...articleStore, article: data });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticle();
  }, [id]);

  return { loading, articleStore, updateArticleStore };
};
export { Provider as ArticleProvider, useStore as useArticleStore };
