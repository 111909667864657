import React, { useState } from 'react';
import { Checkbox, DeleteButton, EditButton, SourcesInput, StyledListItem, TitleInput } from './Style';
import { GridRow } from '../../../../../components/layouts/GridRow';
import { Row } from '../../../../../components/layouts/Row';
import { ToggleButton } from '../../../../../components/elements/ToggleButton';

export const ListItem = ({
  sourceData,
  checked,
  articlesSourceTitleFr,
  articlesSourceTitleEn,
  url,
  handleDeleteSource,
  sourceId,
  sourcesData,
  editSourceToArticle,
  interfaceTexts,
  appLocale,
}) => {
  const [updateChecked, setUpdateChecked] = useState(checked);
  const [updatedTitleFr, setUpdatedTitleFr] = useState(articlesSourceTitleFr);
  const [updatedTitleEn, setUpdatedTitleEn] = useState(articlesSourceTitleEn);
  const [updatedUrl, setUpdatedUrl] = useState(url);
  const [isModified, setIsModified] = useState(false);

  const handleUpdateTitleFr = (event) => {
    setUpdatedTitleFr(event.target.value);
    setIsModified(true);
  };

  const handleUpdateTitleEn = (event) => {
    setUpdatedTitleEn(event.target.value);
    setIsModified(true);
  };

  const handleUrlUpdateChange = (event) => {
    setUpdatedUrl(event.target.value);
    setIsModified(true);
  };

  const handleEditSource = async (source) => {
    try {
      const oldSourcesArray = Object.values(sourcesData);
      const updatedSource = oldSourcesArray.find((source) => source === source);
      if (updatedTitleFr) {
        updatedSource.translations[0].title = updatedTitleFr;
      }
      if (updatedTitleEn) {
        updatedSource.translations[1].title = updatedTitleEn;
      }
      if (updatedUrl) {
        updatedSource.url = updatedUrl;
      }
      updatedSource.public = updateChecked;

      if (updatedSource) {
        editSourceToArticle(updatedSource);
        setIsModified(false);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la source :', error);
    }
  };

  const handleSelectedPrivacy = (item) => {
    setUpdateChecked(item === 'Public');
    setIsModified(true);
  };

  const { private: privateText, frenchTitle, englishTitle, articleLink } = interfaceTexts[appLocale];

  return (
    <StyledListItem>
      <GridRow margin="0.1rem" gridGap=".6rem">
        <Row col="2" margin="0rem 0" justify="space-between">
          <ToggleButton
            items={['Public', interfaceTexts[appLocale].private]}
            item={updateChecked ? 'Public' : interfaceTexts[appLocale].private}
            setItemSelected={handleSelectedPrivacy}
          />
          <Row col="2" margin="0.1rem">
            <DeleteButton onClick={() => handleDeleteSource(sourceData)}>
              <i className="fa-duotone fa-minus-circle"></i>
            </DeleteButton>
          </Row>
        </Row>

        <GridRow col="3" colTemplate="5fr 5fr 1fr" gridGap="1rem" margin=" 0 0rem">
          <TitleInput
            value={updatedTitleEn}
            placeholder={appLocale === 'en' ? englishTitle : frenchTitle}
            onChange={handleUpdateTitleEn}
          />
          <TitleInput
            value={updatedTitleFr}
            placeholder={appLocale === 'en' ? frenchTitle : englishTitle}
            onChange={handleUpdateTitleFr}
          />
        </GridRow>

        <GridRow col="3" colTemplate="15fr 1fr" gridGap="1rem" margin=" 0 0rem">
          <SourcesInput
            value={updatedUrl}
            placeholder={appLocale === 'en' ? articleLink : articleLink}
            onChange={handleUrlUpdateChange}
          />
          <EditButton
            onClick={() => handleEditSource(sourceData)}
            style={{ display: isModified ? 'inline-block' : 'none' }}
          >
            <i className="fa-solid fa-check-circle"></i>
          </EditButton>
        </GridRow>
      </GridRow>
    </StyledListItem>
  );
};
