import { useState } from 'react';
import { useCategoriesData } from '../../../../context/CategoriesProvider';

export const useCategoryManager = () => {
  const { categoryStore, addCategoryToArticle, removeCategoryFromArticle } = useCategoriesData();

  const { categoryInfo, allCategoriesOptions } = categoryStore;
  const { categories } = categoryInfo;

  const [selectedCategory, setSelectedCategory] = useState(null);

  const availableCategoriesOptions = allCategoriesOptions.filter(
    (option) => !categories.some((cat) => cat.categoryId === option.categoryId),
  );

  const handleAddCategory = async () => {
    if (selectedCategory) {
      console.log('handleAddCategory. selectedCategory: ', selectedCategory);
      const categoryId = selectedCategory.id;
      const category = allCategoriesOptions.find((option) => option.categoryId === categoryId);

      if (categories.some((cat) => cat.categoryId === categoryId)) {
        return;
      }

      const rank = categories.length + 1;

      try {
        await addCategoryToArticle({ ...selectedCategory, rank });
        setSelectedCategory(null);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteCategory = async (category) => {
    try {
      const categoryToDelete = categories.find((cat) => cat.categoryId === category.categoryId);

      if (categoryToDelete) {
        await removeCategoryFromArticle(categoryToDelete);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectCategory = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  return {
    categories,
    selectedCategory,
    availableCategoriesOptions,
    handleAddCategory,
    handleDeleteCategory,
    handleSelectCategory,
  };
};
