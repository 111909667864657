import Table from '@editorjs/table';

class CustomTable extends Table {
  static get sanitize() {
    return {
      content: {
        br: true,
      },
    };
  }
}

export default CustomTable;
