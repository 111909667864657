// ArticleContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import ApiHttpClient from '../../../utils/api/api-http-client';

const ArticleContext = createContext();

export const ArticleProvider2 = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [publishedArticle, setPublishedArticle] = useState();
  const [loading, setLoading] = useState(false);
  const apiClient = new ApiHttpClient();

  useEffect(() => {
    const storedArticleData = localStorage.getItem('currentArticle');
    if (storedArticleData) {
      setCurrentArticle(JSON.parse(storedArticleData));
    }
  }, []);

  const getArticle = async (id) => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/articles/${id}`);
      // updateArticleStore({ ...articleStore, article: data })
      setCurrentArticle(data);
      localStorage.setItem('currentArticle', JSON.stringify(data));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getPublishedArticle = async (id) => {
    try {
      const { data } = await apiClient.get(`/articles/${id}/get-published-article`);
      setPublishedArticle(data);
    } catch (error) {
      console.log(error);
    }
  };

  const articleContextValue = {
    articles,
    setArticles,
    currentArticle,
    setCurrentArticle,
    getArticle,
    loading,
    getPublishedArticle,
    publishedArticle,
  };

  return <ArticleContext.Provider value={articleContextValue}>{children}</ArticleContext.Provider>;
};

export const useArticleContext = () => useContext(ArticleContext);
