import { useRef, useState, useEffect, useContext } from 'react';
import { H1, TitleInput } from './Style';
import Button from '../../../components/elements/Button';
import { Row } from '../../../components/layouts/Row';
import { CheckIcon } from '../../../components/inputs/SaveableInput';
import { useImageUpload } from '../../../components/hooks/article/useImageUpload';
import { useForm } from '../../../components/hooks/useForm';
import { sendDataToServer } from './requestBodyEditor';
import { useSaveableInput } from '../../../components/hooks/useSaveableInput';
import { useEditor } from '../../../components/hooks/useEditorJS2';
import { Label } from '../../../components/elements/UnderscoreLabel';
import { Container, MainImageArticle, MainImageWrapper } from '../EditArticle/EditArticleForm/Style';
import { useNavigate } from 'react-router-dom';

import { ImageUpload } from './ImageUpload';
import { AuthContext } from '../../../app/context/AuthContext';

const interfaceTexts = {
  fr: {
    saveButton: 'Enregistrer',
    saving: 'Enregistrement...',
    savedSuccessfully: 'Enregistré avec succès',
    saveError: "Erreur lors de l'enregistrement",
  },
  en: {
    saveButton: 'Save',
    saving: 'Saving...',
    savedSuccessfully: 'Saved successfully',
    saveError: 'Error while saving',
  },
};

export const CreateArticleForm = ({ initialValues, id, onSubmit, formType, languageArticle, setLanguageArticle }) => {
  // const { title = '', image = '', content = [], id = null } = initialValues || {}

  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [titleTranslations, setTitleTranslations] = useState({ fr: '', en: '' });
  const [contentTranslations, setContentTranslations] = useState({ fr: [], en: [] });

  const editorRef = useRef(null);

  const navigate = useNavigate();

  const [showImageEditIcon, setShowImageEditIcon] = useState(false);

  let { image } = initialValues || {};

  const { values, setValues, handleChange, handleSubmit, errors } = useForm(initialValues, onSubmit);
  const { editor, isEditorReady, updateContent } = useEditor({
    editorRef,
    contentTranslations,
    formType,
    languageArticle,
    setContentTranslations,
  });

  const { imageUrl, isUploading, handleImageUpload } = useImageUpload();

  const handleSave = async (e) => {
    const savedData = await editor.save();

    const updatedContentTranslations = { ...contentTranslations };
    updatedContentTranslations[languageArticle] = savedData.blocks;

    try {
      const response = await sendDataToServer({
        locale: languageArticle,
        translations: updatedContentTranslations,
        titleTranslations: titleTranslations,
        image: imageUrl,
        formType: formType,
        id: id,
      });

      navigate(`/edit-article/${response.data.id}`);
    } catch (error) {
      // setLoading(false)
      // setError(true)
      // setTimeout(() => {
      //   setError(false) // Reset error state after 4 seconds
      // }, 4000)
    }
  };

  const handleSaveTitle = (newValue) => {
    setTitleTranslations((prevState) => ({
      ...prevState,
      [languageArticle]: newValue,
    }));
  };

  const {
    inputValue,
    setInputValue,
    handleInputChange,
    handleInputFocus,
    handleSaveButtonClick,
    handleKeyDown,
    isEditing,
    inputRef,
  } = useSaveableInput(titleTranslations[languageArticle], handleSaveTitle);

  const renderTitleInput = () => {
    return (
      <TitleInput
        name="title"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
        ref={inputRef}
      />
    );
  };

  useEffect(() => {
    setInputValue(titleTranslations[languageArticle]);
  }, [titleTranslations]);

  return (
    <>
      <Container>
        <Label>
          {/* <span> Title:</span> */}
          <H1>
            <Row align="center" justify="flex-start" padding="0" margin="0">
              {renderTitleInput()}
              <CheckIcon className="fa-solid fa-check-circle" onClick={handleSaveButtonClick} />
            </Row>
          </H1>
        </Label>
        <br />

        <ImageUpload imageUrl={imageUrl} handleImageUpload={handleImageUpload} />
        <div ref={editorRef}></div>

        <br />

        <Button onClick={handleSave} style={{ position: 'absolute', right: '0rem', top: '0rem', zIndex: 1 }}>
          {loading && !success && !error && interfaceTexts[appLocale]?.saving}
          {!loading && success && !error && interfaceTexts[appLocale]?.savedSuccessfully}
          {!loading && !success && error && interfaceTexts[appLocale]?.saveError}
          {!loading && !success && !error && interfaceTexts[appLocale]?.saveButton}
        </Button>
      </Container>
    </>
  );
};
