import { useContext, useState } from 'react';

import {
  Layout,
  H3,
  CategoryWrapper,
  EditableCategory,
  Category,
  CategoryRow,
  Subcategory,
  SubcategoryRow,
  EditIcon,
  Card,
} from './Style';
import { SearchDropdown } from '../../../../../components/inputs/SearchDropdown';
import { CategoryManager } from './CategoryManager';
import { AuthContext } from '../../../../../app/context/AuthContext';

export const Categories = () => {
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';
  return (
    <Layout>
      <H3>{appLocale === 'en' ? 'Categories' : 'Catégories'}</H3>
      <CategoryManager />
    </Layout>
  );
};
