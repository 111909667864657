import { H3 } from '../Categories/Style';
import { Col } from '../../../../../components/layouts/Col';
import {
  AltInput,
  DescriptionInput,
  EditButton,
  InputRow,
  KeywordRow,
  Layout,
  SubHeading,
  TextInput,
  TitleInput,
} from './Style';
import { useEffect, useState } from 'react';
import { AddButton, Row } from '../Categories/CategoryManager/Style';
import { GridRow } from '../../../../../components/layouts/GridRow';

export const MetaData = ({ keywordsData, editKeywords, seoData, addSeoToArticle, article }) => {
  const [titleFr, setTitleFr] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [descriptionFr, setDescriptionFr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [altFr, setAltFr] = useState('');
  const [altEn, setAltEn] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [keywords, setKeywords] = useState(keywordsData);
  const [isKeywordInputEmpty, setIsKeywordModified] = useState(true);
  const [isAltModified, setAltIsModified] = useState(false);

  useEffect(() => {
    if (seoData.length > 0) {
      const frSeo = seoData.find((seo) => seo.locale === 'fr');
      const enSeo = seoData.find((seo) => seo.locale === 'en');

      if (frSeo) {
        setTitleFr(frSeo.title || '');
        setDescriptionFr(frSeo.description || '');
        setAltFr(frSeo.imageCaption || '');
      }
      if (enSeo) {
        setTitleEn(enSeo.title || '');
        setDescriptionEn(enSeo.description || '');
        setAltEn(enSeo.imageCaption || '');
      }
    } else {
      setTitleFr(article.translations[0]?.title);
      setTitleEn(article.translations[1]?.title);
    }
  }, []);

  const handleTitleFr = (event) => {
    setTitleFr(event.target.value);
    setIsModified(true);
  };

  const handleTitleEn = (event) => {
    setTitleEn(event.target.value);
    setIsModified(true);
  };

  const handleDescriptionFr = (event) => {
    setDescriptionFr(event.target.value);
    setIsModified(true);
  };

  const handleDescriptionEn = (event) => {
    setDescriptionEn(event.target.value);
    setIsModified(true);
  };

  const handleAltFr = (event) => {
    setAltFr(event.target.value);
    setAltIsModified(true);
  };

  const handleAltEn = (event) => {
    setAltEn(event.target.value);
    setAltIsModified(true);
  };

  const handleEditMetaData = async () => {
    if (titleFr.trim() === '' || titleEn.trim() === '' || descriptionFr.trim() === '' || descriptionEn.trim() === '') {
      alert('Veuillez remplir tous les champs.');
      return;
    }

    try {
      const newSeo = [
        {
          locale: 'fr',
          title: titleFr,
          description: descriptionFr,
          imageCaption: altFr,
        },
        {
          locale: 'en',
          title: titleEn,
          description: descriptionEn,
          imageCaption: altEn,
        },
      ];
      await addSeoToArticle(newSeo);
      setIsModified(false);
      setAltIsModified(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour :', error);
    }
  };

  const handleAddKeywords = async () => {
    const trimmedKeywords = keywords.trim();
    if (trimmedKeywords === '') {
      alert('Veuillez remplir tous les champs.');
      return;
    }
    editKeywords(trimmedKeywords);
    setIsKeywordModified(true);
  };

  const handleKeywordsInputChange = (event) => {
    setKeywords(event.target.value);
    setIsKeywordModified(event.target.value.trim() === '');
  };

  return (
    <Layout>
      <H3>Méta-données de l’article</H3>

      <Col>
        <SubHeading>Titre Meta</SubHeading>
        <GridRow col="3" colTemplate="5fr 5fr 1fr" gridGap="1rem" margin=" 0 0.4rem">
          <TitleInput value={titleFr} placeholder="Titre en français" onChange={handleTitleFr} />
          <TitleInput value={titleEn} placeholder="Titre en anglais" onChange={handleTitleEn} />
        </GridRow>
      </Col>
      <Col>
        <SubHeading>Description Meta </SubHeading>
        <GridRow col="3" colTemplate="5fr 5fr 1fr" gridGap="1rem" margin=" 0 0.4rem">
          <DescriptionInput
            value={descriptionFr}
            placeholder="Description en français"
            onChange={handleDescriptionFr}
          />
          <DescriptionInput value={descriptionEn} placeholder="Description en anglais" onChange={handleDescriptionEn} />
          <AddButton onClick={() => handleEditMetaData()} style={{ display: isModified ? 'inline-block' : 'none' }}>
            <i className="fa-solid fa-check-circle"></i>
          </AddButton>
        </GridRow>
      </Col>
      <Col>
        <SubHeading>Image alt</SubHeading>
        <GridRow col="3" colTemplate="5fr 5fr 1fr" gridGap="1rem" margin=" 0 0.4rem">
          <AltInput value={altFr} placeholder="Alt de l'image en francais" onChange={handleAltFr} />
          <AltInput value={altEn} placeholder="Alt de l'image en anglais" onChange={handleAltEn} />
          <AddButton onClick={() => handleEditMetaData()} style={{ display: isAltModified ? 'inline-block' : 'none' }}>
            <i className="fa-solid fa-check-circle"></i>
          </AddButton>
        </GridRow>
      </Col>

      <Col>
        <SubHeading>Mots-Clés</SubHeading>
        <KeywordRow>
          <GridRow col="2" colTemplate="15fr 1fr" gridGap="1rem" margin=" 0 0.4rem">
            <InputRow>
              <TextInput type="text" value={keywords} onChange={handleKeywordsInputChange} placeholder="Mots-Clés" />
            </InputRow>
            <Row width="30%">
              {isKeywordInputEmpty ? null : (
                <AddButton onClick={handleAddKeywords}>
                  <i className="fa-solid fa-check-circle"></i>
                </AddButton>
              )}
            </Row>
          </GridRow>
        </KeywordRow>
      </Col>
    </Layout>
  );
};
