import React from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  resize: vertical;
  background: #f1f1f1;
  border: none;
  border-radius: 2rem;
  width: 100%;
  height: 200px;
  padding: 1rem;
  min-height: 3rem;
  color: black;
  font-size: 1.15rem;
  font-weight: 500;
`;

export const TextArea = React.forwardRef((props, ref) => {
  return <StyledTextArea {...props} ref={ref} />;
});
