import { useState } from 'react';
import { Option, StyledToggle } from './Style';

export const ToggleButton = ({ items = ['fr', 'en'], item, setItemSelected, setInputValue }, ...props) => {
  const [selectedItem, setSelectedItem] = useState(item);

  const handleItemChange = (item) => {
    setSelectedItem(item);
    if (setItemSelected === undefined) return;
    setItemSelected(item);
    if (setInputValue === undefined) return;
    setInputValue('');
  };

  return (
    <StyledToggle {...props}>
      {items.map((item) => (
        <Option
          text={item}
          key={item}
          className={selectedItem === item ? 'active' : ''}
          onClick={() => handleItemChange(item)}
        >
          <span>{item}</span>
        </Option>
      ))}
    </StyledToggle>
  );
};
