const setIdToken = (token) => {
  if (token) {
    // api.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    // console.log('set default api', api);

    localStorage.setItem('idToken', token);
  } else {
    // delete api.defaults.headers.common['Authorization']
    localStorage.removeItem('idToken');
  }
};

export default setIdToken;
