export default {
  fr: {
    IN_PROGRESS: 'En cours',
    TO_BE_REVIEWED: 'A réviser',
    TO_UPDATE_IMAGE: "Mise à jour de l'image nécessaire",
    TO_UPDATE_CONTENT: 'Mise à jour du texte nécessaire',
  },
  en: {
    IN_PROGRESS: 'In progress',
    TO_BE_REVIEWED: 'To review',
    TO_UPDATE_IMAGE: 'Image update needed',
    TO_UPDATE_CONTENT: 'Text update needed',
  },
};
