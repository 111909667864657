import { useSaveableInput } from '../../hooks/useSaveableInput';

import styled, { css } from 'styled-components';
import { Row } from '../../layouts/Row';
import { Input } from '../Input';

export const CheckIcon = styled.i`
  display: ${({ showSaveButton }) => (showSaveButton ? 'block' : 'none')};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const SaveableInput = ({ placeholder, onSave, value }) => {
  const {
    inputValue,
    setInputValue,
    handleInputChange,
    handleInputFocus,
    handleSaveButtonClick,
    handleKeyDown,
    showSaveButton,
    isEditing,
    inputRef,
  } = useSaveableInput(value, onSave);

  return (
    <Row>
      <Input
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
        isEditing={isEditing}
        ref={inputRef}
      />
      <CheckIcon className="fa-solid fa-check-circle" onClick={handleSaveButtonClick} showSaveButton={showSaveButton} />
    </Row>
  );
};
