import { SortControl } from '../../controls/SortControl';
import { TableHead, TableHeading } from './Style';
import { TableSortArrows } from './TableSortArrows';

export const TableHeader = ({ colHeaders }) => {
  return (
    <TableHead>
      <tr>
        {colHeaders.map(({ label, sortKey }) => (
          <TableHeading key={sortKey}>
            {label}
            <SortControl sortKey={sortKey}>
              {label}
              <TableSortArrows />
            </SortControl>
          </TableHeading>
        ))}
      </tr>
    </TableHead>
  );
};
