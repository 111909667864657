import React, { useRef, createContext, useContext, useCallback, useSyncExternalStore } from 'react';

const createHighVelocityContext = (initialState) => {
  const useStoreData = () => {
    const store = useRef(initialState);

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set());

    const set = useCallback((newState) => {
      store.current = { ...store.current, ...newState };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      subscribe,
    };
  };

  const StoreContext = createContext(null || []);

  const Provider = ({ children }) => {
    // const store = useStoreData()
    return <StoreContext.Provider value={useStoreData()}>{children}</StoreContext.Provider>;
  };

  const useStore = (selector) => {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error('useStore must be used within a Provider');
    }

    const state = useSyncExternalStore(store.subscribe, () => selector(store.get(), () => selector(initialState)));

    // const setState = (newState) => {
    //   store.set(newState)
    // }

    const setState = useCallback((newState) => {
      store.set(newState);
    }, []);

    return [state, setState];
  };

  return { Provider, useStore };
};

export default createHighVelocityContext;
