import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.primary || props.bgColor};
  width: ${(props) => props.width};
  color: ${(props) => 'white' || props.textColor};
  border-radius: 1rem;
  padding: 1.4rem 2rem;
  cursor: pointer;
  font-weight: bold;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  &:disabled {
    background: #d1e9ff;
    cursor: not-allowed;
  }

  ${(props) =>
    props.borderColor &&
    css`
      border: 1px solid ${(props) => props.borderColor};
    `}

  /* margin: auto 0rem; */
  /* border: 2px solid ${(props) => props.theme.colors.secondary}; */
  /* border-radius: 8px; */
  /* transition: ${({ theme }) => theme.transitionSpeed} all ease-in-out; */

  svg,
  i {
    margin-left: 0.4rem;
  }

  /* &:hover,
  &:active {
    color: ${(props) => props.theme.colors.secondary};
  } */
`;

export default StyledButton;
