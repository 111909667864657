// import styled from 'styled-components'

// export const UploadButton = styled.label`
//   display: inline-block;
//   width: 100%;
//   height: 20rem;
//   border-radius: 1rem;
//   background-color: #f3f3f3;
//   border: 2px dashed #ccc;
//   text-align: center;
//   cursor: pointer;
//   overflow: hidden;
//   transition: border-color 0.3s ease-in-out;

//   &:hover {
//     border-color: ${({ theme }) => theme.colors.primary};
//   }

//   input[type='file'] {
//     display: none;
//   }

//   &:before {
//     content: '+';
//     display: inline-block;
//     position: absolute;
//     /* margin-top: 40px; */
//     top: 50%;
//     transform: translateY(-50%);
//     font-size: 40px;
//     line-height: 40px;
//     color: #ccc;
//     transition: color 0.3s ease-in-out;
//   }

//   &:hover:before {
//     color: ${({ theme }) => theme.colors.primary};
//   }
// `

import React from 'react';
import styled from 'styled-components';

const UploadButtonWrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
  background-color: #f3f3f3;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  transition: border-color 0.3s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  input[type='file'] {
    display: none;
  }

  &:before {
    content: '+';
    display: inline-block;
    position: absolute;
    /* margin-top: 40px; */
    top: 50%;
    transform: translateY(-50%);
    font-size: 40px;
    line-height: 40px;
    color: #ccc;
    transition: color 0.3s ease-in-out;
  }

  &:hover:before {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #1890ff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 6px;
  display: inline-block;
`;

export const UploadButton = ({ onClick, children, isUploading }) => {
  return (
    <UploadButtonWrapper>
      {isUploading ? <Spinner /> : null}
      {children}
      {/* <input type='file' style={{ display: 'none' }} onChange={onClick} /> */}
    </UploadButtonWrapper>
  );
};
