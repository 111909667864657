import { Pagination } from '../Pagination';
import { StyledTable, TableBody } from './Style';
import { useResourceUpdate } from '../../hooks/useResourceUpdate';
import { TableHeader } from './TableHeader';
import { TableLineItem } from './TableLineItem';
import { SearchBar } from '../../inputs/SearchBar';
export const Table = ({
  searchBar,
  name,
  colHeaders,
  colCustomSize,
  lineItems,
  onDeleteClick,
  onViewEditClick,
  isCreating,
  handleKeyDown,
  onSaveAuthorClick,
  setSearch,
  paginationOptions,
  onPageChange,
}) => {
  const { resource, updateResource } = useResourceUpdate(`/${name}`);
  return (
    <>
      {searchBar ? (
        <SearchBar type="text" placeholder="Rechercher" onChange={(e) => setSearch(e.target.value)} />
      ) : null}
      <StyledTable colCustomSize={colCustomSize}>
        <colgroup>
          {colCustomSize?.map((size, index) => (
            <col key={index} style={{ width: size }} />
          ))}
        </colgroup>
        <TableHeader colHeaders={colHeaders} />
        <TableBody>
          {lineItems?.map((row) => (
            <TableLineItem
              name={name}
              key={row.id}
              row={row}
              onDeleteClick={onDeleteClick}
              onViewEditClick={onViewEditClick}
              resource={resource}
              updateResource={updateResource}
              isCreating={isCreating}
              handleKeyDown={handleKeyDown}
              onSaveAuthorClick={onSaveAuthorClick}
            />
          ))}
        </TableBody>
      </StyledTable>
      <Pagination
        pageCount={paginationOptions?.totalPages}
        currentPage={paginationOptions?.currentPage}
        onPageChange={onPageChange}
      />
    </>
  );
};
