import { useEffect, useRef, useState } from 'react';

export const useSaveableInput = (value, onSave) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setShowSaveButton(true);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      onSave(event.target.value);
      setShowSaveButton(false);
    }, 200);
  };

  const handleInputFocus = () => {
    setIsEditing(true);
  };

  const handleSaveButtonClick = () => {
    onSave(inputValue);
    setShowSaveButton(false);
    setIsEditing(false);
    inputRef.current.blur();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveButtonClick();
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return {
    inputValue,
    setInputValue,
    handleInputChange,
    handleInputFocus,
    handleSaveButtonClick,
    handleKeyDown,
    showSaveButton,
    isEditing,
    setIsEditing,
    inputRef,
  };
};
