import { useState, useContext } from 'react';
import { StatusIndicator } from '../../elements/StatusIndicator';
import { ProfilePicture } from '../ProfilePictureThumbnail';
import { TableRow } from './Style';
import { TableActionButton } from './TableActionButton';
import { formatDate } from '../../../utils/maths/formatDate';
import { Input } from '../../inputs/Input';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../app/context/AuthContext';
import { buildArticleUrl } from '../../../features/articleManagement/buildArticleUrl';
import statusTranslations from '../../../translations/status';

export const TableLineItem = ({
  row,
  onDeleteClick,
  onViewEditClick,
  resource,
  updateResource,
  name,
  isCreating,
  handleKeyDown,
  authors,
  setAuthors,
  onNavigateArticle,
  onSaveAuthorClick,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(row);
  const [isProfilePictureEditing, setIsProfilePictureEditing] = useState(false);
  const { appLocale } = useContext(AuthContext)?.user || {};

  const handleEditClick = () => {
    setIsEditing(true);
  };
  // console.log(row)
  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedData(row);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async (row) => {
    setIsEditing(false);
    const resource = await updateResource(editedData);
    // setEditedData(resource)
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setEditedData((prevState) => ({
        ...prevState,
        picture: reader.result,
      }));
      setIsProfilePictureEditing(false);
    };
  };

  if (name === 'dashboardTable') {
    return (
      <TableRow key={row.id}>
        <td style={{ textAlign: 'left', paddingLeft: '3rem' }}>
          <a href={`/edit-article/${row.id}`} style={{ color: 'rgb(23, 92, 211)' }}>
            {row.title || "This article doesn't have a title"}
          </a>
        </td>
        <td style={{ textAlign: 'center' }}>{row.publishStatus?.join(' - ')}</td>
        <td style={{ textAlign: 'center' }}>
          {row.status?.fr === null ? '' : statusTranslations[appLocale ?? 'FR'][row.status?.fr]}
        </td>
        <td style={{ textAlign: 'center' }}>
          {row.status?.en === null ? '' : statusTranslations[appLocale ?? 'FR'][row.status?.en]}
        </td>
        <td style={{ textAlign: 'center' }}>{formatDate(row.updatedAt)}</td>
        <td>
          {row.publishStatus.length > 0 && (
            <Link to={buildArticleUrl(appLocale, row)} target="_blank">
              <TableActionButton iconClassName="fa-solid fa-arrow-up-right-from-square" />
            </Link>
          )}
        </td>
      </TableRow>
    );
  }

  if (name === 'categories') {
    return (
      <TableRow key={row.id}>
        <td style={{ textAlign: 'left', paddingLeft: '3rem' }}>{row.translations[1].title}</td>
        <td>{formatDate(row.updatedAt)}</td>
        <td>
          <StatusIndicator status={'active'} />
        </td>
        <td>
          <TableActionButton
            // label='View & Edit'
            iconClassName="fa-solid fa-pen-to-square"
            onClick={() => onViewEditClick(row)}
          />
          <TableActionButton
            // label='Delete'
            iconClassName="fa-solid fa-trash"
            onClick={() => onDeleteClick(row.id)}
          />
        </td>
      </TableRow>
    );
  }

  // authors
  return (
    <TableRow key={row.id} isEditing={isEditing}>
      <td style={{ textAlign: 'left', paddingLeft: '3rem' }}>
        {isCreating && row.id === null && (
          <Input
            autoFocus
            onKeyDown={handleKeyDown}
            onChange={(event) => {
              const index = authors.findIndex((author) => author.id === null);
              const updatedAuthor = { ...authors[index] };
              updatedAuthor.name = event.target.value;
              setAuthors([...authors.slice(0, index), updatedAuthor, ...authors.slice(index + 1)]);
            }}
          />
        )}
        {isEditing ? (
          <Input type="text" name="name" value={editedData?.name || ''} onChange={handleInputChange} />
        ) : (
          editedData?.name
        )}
      </td>
      <td>
        {!isProfilePictureEditing && (
          <ProfilePicture viewType={row.picture} alt="" onClick={() => setIsProfilePictureEditing(true)} />
        )}
        {isProfilePictureEditing && <Input type="file" name="picture" onChange={handleProfilePictureChange} />}
      </td>
      <td>
        <StatusIndicator status={'active'} />
      </td>
      <td>
        {isCreating && (
          <TableActionButton iconClassName="fa-solid fa-check-circle" onClick={() => onSaveAuthorClick(row)} />
        )}

        {isEditing ? (
          <TableActionButton
            // label='View & Edit'
            iconClassName="fa-solid fa-check-circle"
            onClick={() => handleSaveClick(row)}
          />
        ) : (
          <TableActionButton
            // label='View & Edit'
            iconClassName="fa-solid fa-pen-to-square"
            onClick={() => handleEditClick(row)}
          />
        )}
        <TableActionButton
          // label='Delete'
          iconClassName="fa-solid fa-trash"
          onClick={() => onDeleteClick(row.id)}
        />
      </td>
    </TableRow>
  );
};
