import { AuthorTable } from '../../features/settings/AuthorTable';
import { CategoryTable } from '../../features/settings/CategoryTable';

import { H1 } from '../../components/elements/H1';
import { PageContainer as Page } from '../../components/layouts/PageContainer';
import { Container, Style } from './Style';
import { AuthContext } from '../../app/context/AuthContext';
import { useContext } from 'react';

const interfaceTexts = {
  en: {
    pageTitle: "System's settings",
    authorTableTitle: 'Authors access',
    categoryTableTitle: 'Category access',
  },
  fr: {
    pageTitle: 'Paramètres du système',
    authorTableTitle: 'Accès des auteurs',
    categoryTableTitle: 'Accès des catégories',
  },
};

export const Settings = () => {
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  return (
    <Style>
      <Page>
        <H1>{interfaceTexts[appLocale]?.pageTitle}</H1>
        <Container>
          <AuthorTable title={interfaceTexts[appLocale]?.authorTableTitle} />
          <CategoryTable title={interfaceTexts[appLocale]?.categoryTableTitle} />
        </Container>
      </Page>
    </Style>
  );
};
