import styled from 'styled-components';

export const Switch = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 5px;

  > span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-family: 'Helvetica', Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    width: 50%;
    text-align: center;

    &.on {
      left: 0;
      padding-left: 2px;
      color: ${({ theme }) => theme.colors.primary};
    }

    &.off {
      right: 0;
      padding-right: 4px;
      color: #fff;
    }
  }

  input.check-toggle-round-flat:checked ~ .off {
    color: ${({ theme }) => theme.colors.primary};
  }

  input.check-toggle-round-flat:checked ~ .on {
    color: #fff;
  }

  .check-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  .check-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input.check-toggle-round-flat + label {
    padding: 2px;
    width: 97px;
    height: 35px;
    background-color: ${({ theme }) => theme.colors.primary};
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }
  input.check-toggle-round-flat + label:before,
  input.check-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: '';
  }

  input.check-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }
  input.check-toggle-round-flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 48px;
    background-color: #fff;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    -ms-border-radius: 52px;
    -o-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
  }

  input.check-toggle-round-flat:checked + label {
  }

  input.check-toggle-round-flat:checked + label:after {
    margin-left: 41px;
  }
`;

export const ToggleSwitch = ({ active, onClick }) => {
  return (
    <Switch>
      <input
        id="toggle"
        checked={!active}
        class={`check-toggle check-toggle-round-flat ${active ? 'on' : 'off'}`}
        type="checkbox"
        onClick={onClick}
      />
      <label for="toggle"></label>
      <span class="on">On</span>
      <span class="off">Off</span>
    </Switch>
  );
};
