import { useContext, useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import { EDITOR_JS_TOOLS } from '../../features/articleManagement/EditArticle/EditorJS_Tools/EditorJS_constants';
import { AuthContext } from '../../app/context/AuthContext';

export const useEditor = ({ editorRef, contentTranslations, formType, languageArticle, setContentTranslations }) => {
  const [editor, setEditor] = useState(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isUpdatingContent, setIsUpdatingContent] = useState(false);
  const [previousLanguage, setPreviousLanguage] = useState(languageArticle);

  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  useEffect(() => {
    let newEditor = null;

    const initializeEditor = async () => {
      try {
        newEditor = await new EditorJS({
          holder: editorRef.current,
          tools: EDITOR_JS_TOOLS,
          // autofocus: true,
          placeholder: appLocale === 'en' ? 'Write your content here...' : 'Écrivez votre contenu ici...',
          data: {
            blocks: contentTranslations[languageArticle] || [],
          },
          onReady: () => {
            setEditor(newEditor);
            setIsEditorReady(true);
          },
        });
      } catch (error) {
        console.error('Error initializing editor:', error);
      }
    };

    initializeEditor();

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [contentTranslations]);
  // }, [])

  const updateContent = async () => {
    setIsUpdatingContent(true);
    if (editor && isEditorReady) {
      const savedBlocks = await editor.save();

      // console.log(savedBlocks.blocks, 'savedBlocks in', previousLanguage)

      setContentTranslations((prevState) => ({
        ...prevState,
        [previousLanguage]: savedBlocks.blocks || [],
      }));

      setIsUpdatingContent(false);
      editor.render({ blocks: contentTranslations[languageArticle] || [] });
    }
  };

  useEffect(() => {
    if (editor && isEditorReady) {
      setPreviousLanguage(languageArticle);
      updateContent();
    }
  }, [languageArticle]);

  return { editor, isEditorReady };
};
