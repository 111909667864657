import styled from 'styled-components';

export const Style = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 5rem;
`;

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
