import React, { useEffect, useRef } from 'react';
import {
  select,
  scaleBand,
  axisBottom,
  stack,
  max,
  scaleLinear,
  axisLeft,
  stackOrderAscending,
  stackOrderDescending,
} from 'd3';
import useResizeObserver from '../../hooks/useResizeObserver';
import styled from 'styled-components';

const StyledChartContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  height: 225px;
  box-sizing: border-box;
  line {
    stroke: #909090;
    stroke-dasharray: 5 5;
    stroke-width: 1;
    opacity: 0.6;
  }
  path {
    stroke: #909090;
  }
  text {
    fill: #909090;
    font-size: 12px;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
  }
  .domain {
    display: none;
  }
  @media screen and (max-width: 1400px) {
    max-width: 700px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 900px;
  }
  @media screen and (min-width: 1920px) {
    max-width: 1100px;
  }
`;

export const StackedBarChart = ({ data, keys, colors, stackOrder = 'descending', fontSize = '12px' }) => {
  const svgRef = useRef();
  const yAxisRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);

  useEffect(() => {
    const svg = select(svgRef.current);
    const yAxisSvg = select(yAxisRef.current);
    const { height } = dimensions || wrapperRef.current.getBoundingClientRect();

    const stackGenerator = stack()
      .keys(keys)
      .order(stackOrder === 'descending' ? stackOrderDescending : stackOrderAscending);
    const layers = stackGenerator(data);

    const extent = [0, max(layers, (layer) => max(layer, (sequence) => sequence[1]))];

    const yScale = scaleLinear()
      .domain(extent)
      .range([height + 50, 0]);

    const yAxis = axisLeft(yScale)
      .tickSize(-(data.length * 80))
      .ticks(10);
    yAxisSvg.select('.y-axis').attr('height', height).call(yAxis);

    const xScale = scaleBand()
      .domain(data.map((d) => d.key))
      .range([0, data.length * 80])
      .padding(0.5);

    const xAxis = axisBottom(xScale).tickSize(0);

    svg
      .attr('width', data.length * 80)
      .attr('height', height)
      .selectAll('.layer')
      .data(layers)
      .join('g')
      .attr('class', 'layer')
      .attr('fill', (layer) => colors[layer.key])
      .selectAll('rect')
      .data((layer) => layer)
      .join('rect')
      .attr('class', 'data-bar')
      .attr('x', (sequence) => xScale(sequence.data.key))
      .attr('width', xScale.bandwidth())
      .attr('y', (sequence) => yScale(sequence[1]))
      .attr('height', (sequence) => {
        if (!isNaN(sequence[0]) && !isNaN(sequence[1])) {
          return yScale(sequence[0]) - yScale(sequence[1]);
        } else {
          return 0;
        }
      });

    svg
      .select('.x-axis')
      .attr('transform', `translate(0, ${height + 50})`)
      .call(xAxis)
      .selectAll('text')
      .attr('dy', '24px')
      .style('fill', '#909090')
      .style('font-size', fontSize);
  }, [colors, data, dimensions, keys]);

  return (
    <>
      <StyledChartContainer ref={wrapperRef}>
        <div>
          <svg
            ref={yAxisRef}
            style={{
              overflow: 'visible',
            }}
            width="1"
          >
            <g className="y-axis" />
          </svg>
        </div>
        <div
          style={{
            overflowX: 'auto',
            marginBottom: '-100px',
          }}
        >
          <svg
            style={{
              overflow: 'visible',
              margin: 0,
            }}
            ref={svgRef}
          >
            <g className="x-axis" />
          </svg>
        </div>
      </StyledChartContainer>
    </>
  );
};
