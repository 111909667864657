import axios from 'axios';
import setAuthToken from '../../utils/auth/setAuthToken';
import { authService } from '../../features/authService';

// create instance of axios
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/blog`,
  // baseURL: '/api',
  // withCredentials: true,
  // headers: {
  //   'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  // },
});

// intercept for errors
api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      authService.logout();
      setAuthToken(null);
      throw new Error(err.response.data.error);
    } else if (err.response.status === 404) {
      throw new Error('server path not found');
    } else {
      //TODO
      //(showAlertMessage(err.message))(store.dispatch);
    }

    throw new Error(err.response.data.message || err.response.data.error);
  },
);

// Temporary axios instance for the articles endpoint. This is for dev testing purposes,
// until the api gateway is deployed.
export const servicesApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/project`,
});

export default api;
