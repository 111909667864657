import styled, { css } from 'styled-components/macro';

const drawerWidth = 240;

export const SideBarWrapper = styled.div`
  display: flex;
  transition: position 1s;

  ${({ open }) =>
    !open &&
    css`
      position: fixed;
      height: 100vh;
    `}
`;

export const H2 = styled.h2`
  color: white;
  font-size: 1.55rem;
  font-size: 1.35rem;
  margin: 0 auto;
  text-transform: uppercase;
  opacity: ${({ open }) => (open ? '0' : '1')};
  transition: opacity ${({ theme }) => theme.transitionSpeed} ease-in-out;
`;

export const Drawer = styled.div`
  width: 24rem;
  flex-shrink: 0;
  height: 100%;

  background-color: #fafafa;
  width: ${({ open }) => (open ? '24rem' : '6.5rem')};
  transition: ${({ theme }) => theme.transitionSpeed};

  /* margin-left: ${(props) => (props.open ? '0' : `0px`)}
    ${(props) =>
    props.open &&
    css`
      transition: ${({ theme }) => theme.transitionSpeed};
    `}; */
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 64px;
  background: ${({ theme }) => theme.colors.primary};
`;

export const IconButton = styled.button`
  color: rgba(0, 0, 0, 0.54);
`;

export const StyledChevronLeftIcon = styled.i`
  font-size: 1.4rem;
  color: white;
`;

export const ChevronLeftIcon = () => (
  <StyledChevronLeftIcon>
    <i className="fas fa-chevron-left"></i>
  </StyledChevronLeftIcon>
);

export const Divider = styled.hr`
  margin: 8px 0;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  list-style: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start;
  justify-content: center; */
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: ${({ open }) => (open ? 'flex-start' : 'center')};
  padding-left: ${({ open }) => (open ? '3rem' : '0rem')};
  width: 100%;

  position: relative;
  cursor: pointer;

  &.active {
    background: #fafafa;
    box-shadow: 0px 10px 49px rgba(0, 0, 0, 0.13);
  }
`;

export const ListItemIcon = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  i {
    color: ${({ theme }) => theme.colors.primary2};
    font-size: 2.2rem;
    cursor: pointer;
  }
`;

export const ListItemLabel = styled.div`
  position: absolute;
  left: 93%;

  white-space: nowrap;
  background: white;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  color: black;
  text-align: left;
  font-weight: bold;
  padding: 0.2rem 0.6rem;
  border-radius: 0.6rem;

  animation: reveal 0.5s forwards;
  animation-duration: 1s;

  @keyframes reveal {
    from {
      left: 100%;
      opacity: 0;
    }
    to {
      left: 110%;
      opacity: 1;
    }
  }
`;

export const ListItemText = styled.span`
  opacity: ${(props) => props.opacity};
  width: 100%;
  height: 100%;
  margin-left: 0.9rem;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: auto;
  cursor: pointer;
  white-space: nowrap;
`;

export const ListItemButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.open ? 'initial' : 'center')};
  padding: 1.2rem 1.6rem;
  line-height: 1.5;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  font-weight: medium;
`;
