import styled from 'styled-components';

export const StyledLanguageToggle = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 2rem 2rem 2rem 2rem;
  height: 3rem;
`;

export const Option = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.2rem;
  font-size: 1.1rem;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease-in-out;

  span {
    z-index: 20;
    font-size: 1rem;
    margin: auto;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    background: white;
    border-radius: 50%;
    width: calc(100% + 0.8rem);
    height: calc(100% + 0.8rem);
    top: -0.4rem;
    left: -0.4rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary};

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
    }

    &:after {
      content: '';
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
      color: ${({ theme }) => theme.colors.primary};
      background: white;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      transition: transform 0.2s ease-in-out;
    }
  }

  &:hover {
    color: white;

    &:before {
      opacity: 0.2;
    }
  }
`;
