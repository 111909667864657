import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Input } from '../../components/inputs/Input';
import { Select } from '../../features/articleManagement/EditArticle/MetaPanel/Categories/CategoryManager/Style';
import { Page } from '../../features/dashboard/Dashboard/Style';
import { TextArea } from '../../components/inputs/TextArea';
import ApiHttpClient from '../../utils/api/api-http-client';

// const TitleInput = styled.input`
//   margin-bottom: 10px;
// `

const ImportButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 30rem;
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  /* margin: 0 auto; */
`;

export const ImportArticlePage = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [selectedLanguage, setSelectedLanguage] = useState('fr');
  const apiClient = new ApiHttpClient();

  const navigate = useNavigate();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleSelectLanguage = (selectedLanguage) => {
    setSelectedLanguage(selectedLanguage);
  };

  const handleImport = async () => {
    let reqBody = {
      translations: [
        {
          locale: selectedLanguage.value,
          title: title,
          content: content,
        },
      ],
    };

    try {
      const response = await apiClient.post('/articles/import/semji', reqBody);

      if (response.data) {
        navigate(`/edit-article/${response.data.id}`);
      } else {
        console.error('Failed to import article');
      }
    } catch (error) {
      console.error('Error importing article', error);
    }
  };

  return (
    <Page>
      <Select
        value={selectedLanguage}
        options={[
          { value: 'fr', label: 'French' },
          { value: 'en', label: 'English' },
        ]}
        isClearable
        onChange={handleSelectLanguage}
        placeholder="Select import language"
      />
      <br />
      <Input type="text" placeholder="Enter article title" value={title} onChange={handleTitleChange} />
      <br />
      <TextArea placeholder="Paste article content here" value={content} onChange={handleContentChange} />
      <br />
      <ImportButton onClick={handleImport}>
        <i
          className="fa-solid fa-file-import"
          style={{ margin: 0, fontSize: '2rem', transform: 'translateX(-0.2rem)', marginRight: '.5rem' }}
        ></i>{' '}
        Import
      </ImportButton>
    </Page>
  );
};
