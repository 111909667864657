import { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);

  return <ModalContext.Provider value={{ modalContent, setModalContent }}>{children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);
