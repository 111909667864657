import React, { useState } from 'react';
import styled from 'styled-components';

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  /* background-color: ${({ theme }) => theme.colors.primary1}; */
  border-radius: 20px;
  padding: 1rem 0;
  max-width: 30rem;
  height: 0.5rem;
  margin: 0.6rem 0;
  margin-left: auto;
`;

const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;

  /* ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: transparent;
    border-radius: inherit;
    transition: background-color 0.3s ease-in-out; */
  /* } */
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  background: ${({ theme, hasValue }) => (hasValue ? `${theme.colors.primary}` : `${theme.colors.primary}10`)};
  color: #fafafa;
  font-size: 16px;
  padding: 0.8rem;
  border-radius: 0.8rem;
  outline: none;
  /* opacity: ${({ showInput }) => (showInput ? '1' : '0')};
  width: ${({ showInput }) => (showInput ? '100%' : '0')}; */
  transition:
    opacity 0.3s ease-in-out,
    width 0.3s ease-in-out;

  ::placeholder {
    color: ${({ theme }) => theme.colors.text2};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.primary};

    &::placeholder {
      color: white;
    }
  }
`;

export const SearchBar = ({ placeholder, onChange }) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleIconClick = () => {
    setShowInput(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <SearchBarWrapper>
      <SearchIconWrapper>
        <i className="fa fa-search" color="#ffffff" />
      </SearchIconWrapper>
      <SearchInput
        placeholder={placeholder}
        onChange={handleInputChange}
        value={inputValue}
        hasValue={inputValue !== ''}
      />
    </SearchBarWrapper>
  );
};
