import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${(props) => (props.justify && props.justify) || 'center'};
  align-items: ${(props) => (props.align && props.align) || 'center'};
  padding: ${(props) => props.padding || '2rem 3rem'};
  width: 100%;
  height: ${(props) => props.height};
  background: #ffffff;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.04),
    0 6px 8px rgba(0, 0, 0, 0.04),
    0 8px 16px rgba(0, 0, 0, 0.04);

  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  border-radius: 12px;

  gap: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;
