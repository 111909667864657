import { useEffect, useState } from 'react';
import createHighVelocityContext from '../../../utils/createHighVelocityContext';
import ApiHttpClient from '../../../utils/api/api-http-client';

const { Provider, useStore } = createHighVelocityContext({
  categories: [],
  isCreating: false,
});

export const useCategories = () => {
  const [categoryStore, updateCategoryStore] = useStore((store) => store);
  const [categories, setCategories] = useState([]);
  const apiClient = new ApiHttpClient();

  const getAllCategories = async () => {
    const { data } = await apiClient.get('/categories');

    const categoriesAdjusted = data.map((category) => ({
      ...category,
      editMode: false,
    }));

    updateCategoryStore({ categories: categoriesAdjusted });

    setCategories(categoriesAdjusted);
  };

  const createCategory = async (newCategory) => {
    const req = {
      translations: newCategory,
      parent: null,
    };

    const { data } = await apiClient.post('/categories', req);

    const updatedCategories = [...categories, data];
    setCategories(updatedCategories);

    updateCategoryStore({ categories: updatedCategories });
  };

  const updateCategory = async (updatedCategory) => {
    const { data } = await apiClient.put(`/categories/${updatedCategory.id}`, updatedCategory);
    const updatedCategories = categories.map((category) => (category.id === data.id ? data : category));
    setCategories(updatedCategories);

    updateCategoryStore({ categories: updatedCategories });
  };

  const deleteCategory = async (id) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      const { data } = await apiClient.delete(`/categories/${id}`);
      const filteredCategories = categories.filter((category) => category.id !== id);
      setCategories(filteredCategories);

      updateCategoryStore({ categories: filteredCategories });
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return {
    categories,
    setCategories,
    categoryStore,
    updateCategoryStore,
    getAllCategories,
    createCategory,
    updateCategory,
    deleteCategory,
  };
};

export { Provider as CategoriesProviderSettings };
