import authApi from '../../utils/api/authApi';

const frontendId = process.env.REACT_APP_FRONTEND_ID;

export const authService = {
  checkAuthentication: async () => {
    try {
      const frontendId = process.env.REACT_APP_FRONTEND_ID;
      const response = await authApi.get(`/auth/status/${frontendId}`);
      return response.data;
    } catch (error) {
      console.error('Authentication check error:', error);
      throw error;
    }
  },
  login: async (credentials) => {
    try {
      window.location.replace(`${process.env.REACT_APP_API_URL}/login/${frontendId}`);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },
  logout: async () => {
    try {
      window.location.replace(`${process.env.REACT_APP_API_URL}/logout/${frontendId}`);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  },
  getUserData: async () => {
    // Unused for the moment
    try {
      const response = await authApi.get('/user');
      return response.data;
    } catch (error) {
      console.error('Get user data error:', error);
      throw error;
    }
  },
};
