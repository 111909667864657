import styled from 'styled-components';
import { H1 as H1r } from '../../../components/elements/H1';

import { PageContainer } from '../../../components/layouts/PageContainer';

export const H1 = styled(H1r)`
  font-size: 2.6rem;
  color: ${({ theme }) => theme.colors.primary2};
`;

export const Page = styled(PageContainer)`
  width: 90%;
  margin: auto;
  padding-top: 5rem;
`;

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
