export const interfaceTexts = {
  en: {
    dashboard: 'Dashboard',
    createArticle: 'Create Article',
    settings: 'Settings',
    statistics: 'Statistics',
    logout: 'Logout',
  },
  fr: {
    dashboard: 'Tableau de bord',
    createArticle: 'Créer un article',
    settings: 'Paramètres',
    statistics: 'Statistiques',
    logout: 'Se déconnecter',
  },
};
