import { useState, useEffect } from 'react';

export const useHighlight = (onHighlightSelected) => {
  const [highlightedText, setHighlightedText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleSelect = async () => {
      const selection = window.getSelection();
      const selectedText = selection.toString();

      if (selectedText) {
        setHighlightedText(selectedText);
        setIsLoading(true);

        try {
          await onHighlightSelected(selectedText);
        } catch (error) {
          // Handle error here
        }

        setIsLoading(false);
      }
    };

    document.addEventListener('mouseup', handleSelect);

    return () => {
      document.removeEventListener('mouseup', handleSelect);
    };
  }, [onHighlightSelected]);

  return { highlightedText, setHighlightedText, isLoading };
};
