import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  border-radius: 2.5rem;
  width: 100%;
  min-height: 35rem;
  border-spacing: 0.2rem 1.5rem;
  text-align: center;

  /* border-collapse: 0rem; */
`;

export const TableHead = styled.thead`
  background-color: ${({ theme }) => theme.colors.primary};

  height: 2rem;
  color: white;

  td {
    color: white;
  }
`;

export const TableHeading = styled.th`
  padding: 0.7rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;

  &:first-of-type {
    border-radius: 2.5rem 0 0 2.5rem;
    text-align: left;
    padding-left: 3rem;
  }
  &:last-of-type {
    border-radius: 0 2.5rem 2.5rem 0;
  }
`;

export const TableBody = styled.tbody`
  font-weight: medium;

  & .data-rows:nth-child(even) {
    background-color: #f2f2f2;
  }

  & .data-rows:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    cursor: pointer;
  }
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    background-color: #e7eefa;
  }

  ${({ isEditing }) =>
    isEditing &&
    css`
      background: #e7eefa;
    `}

  td:first-of-type {
    width: 50%;
  }
  td:nth-of-type(2) {
    width: 25%;
  }
`;

export const TableCell = styled.td`
  padding: 12px;
`;
