import { useState, useEffect } from 'react';
import ApiHttpClient from '../../utils/api/api-http-client';

export const useResourceUpdate = (resourceUrl) => {
  const [resource, setResource] = useState(null);
  const apiClient = new ApiHttpClient();

  const updateResource = async (updatedData) => {
    try {
      const response = await apiClient.put(`${resourceUrl}/${updatedData.id}`, updatedData);
      setResource(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // ;(async () => {
    //   const response = await api.get(resourceUrl)
    //   setResource(response.data)
    // })()
  }, [resource, resourceUrl]);

  return { resource, updateResource };
};
