import { Routes, Route, useLocation } from 'react-router-dom';

import GlobalStyle from './theme/global-styles';

import { StatisticsPage } from '../pages/Statistics';
import { DashboardPage } from '../pages/Dashboard';
import { EditArticlePage } from '../pages/EditArticle';
import PrivateRoute2 from '../utils/routing/PrivateRoute2';
import { ScrollToTop } from '../utils/routing/ScrollToTop';
import { CreateArticlePage } from '../pages/CreateArticle';
import { Settings } from '../pages/Settings';
import { ImportArticlePage } from '../pages/ImportArticle';
import { CallbackPage } from '../pages/Callback';

const App = () => {
  const location = useLocation();

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />

      {/* <Header /> */}
      <Routes location={location} key={location.pathname}>
        <Route
          exact
          path="/"
          element={
            <PrivateRoute2>
              <DashboardPage />
            </PrivateRoute2>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute2>
              <DashboardPage />
            </PrivateRoute2>
          }
        />
        <Route
          exact
          path="/edit-article/:id"
          element={
            <PrivateRoute2>
              <EditArticlePage />
            </PrivateRoute2>
          }
        />
        <Route
          exact
          path="/create-article"
          element={
            <PrivateRoute2>
              <CreateArticlePage />
            </PrivateRoute2>
          }
        />
        <Route
          exact
          path="/import-article"
          element={
            <PrivateRoute2>
              <ImportArticlePage />
            </PrivateRoute2>
          }
        />
        <Route
          exact
          path="/settings"
          element={
            <PrivateRoute2>
              <Settings />
            </PrivateRoute2>
          }
        />
        <Route
          exact
          path="/statistics"
          element={
            <PrivateRoute2>
              <StatisticsPage />
            </PrivateRoute2>
          }
        />
        <Route exact path="/callback" element={<CallbackPage />} />
        <Route path="*" element={<p>Page not found</p>} />
      </Routes>
    </>
  );
};

export default App;
