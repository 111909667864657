import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { RightPanelModal } from '../../../components/layouts/RightPanelModal/index.js';
import { MetaPanel } from './MetaPanel/index.js';

import { PageContainer } from '../../../components/layouts/PageContainer';

import { Container } from './EditArticleForm/Style';

import { MetaTextSuggestion } from './MetaTextSuggestion';

import { useArticleTranslation } from '../../../components/hooks/article/useArticleTranslation';
import { useHighlight } from '../../../components/hooks/useHighlight';
import { useOpenAI } from '../../../components/hooks/useOpenAI';

import { EditArticleForm } from './EditArticleForm';
import { useArticleData as useArticleData2 } from '../../../components/hooks/article/useArticleData.js';
import { useArticleData } from '../context/ArticleDataProvider/index.js';
import { useCategoriesData, useCategoriesStore } from '../context/CategoriesProvider/index.js';
import { MobilePreview } from './MobilePreview/index.js';
import { AuthorsContext } from '../../../app/context/AuthorsContext/index.js';
import { useArticleContext } from '../../../app/context/ArticleContext/index.js';
import { useInsights } from './MetaPanel/Insights/useInsights.js';
// import { ArticleDataProvider, useArticleData, useFetchArticleData } from '../../features/articleManagement/context/ArticleDataProvider/index.js'

export const EditArticle = ({
  viewModal,
  setViewModal,
  previewModal,
  setPreviewModal,
  languageArticle,
  setEnglishStatus,
  setFrenchStatus,
}) => {
  const { id } = useParams();

  const {
    sources,
    addSourceToArticle,
    removeSourceToArticle,
    editSourceToArticle,
    seo,
    addSeoToArticle,
    keywords,
    editKeywords,
    loading,
    updateArticleStatus,
    frenchStatus,
    englishStatus,
  } = useArticleData2(id);

  const { article } = useArticleData(id).articleStore;

  const { currentArticle, getArticle, getPublishedArticle } = useArticleContext();

  useEffect(() => {
    getArticle(id);
  }, []);

  useEffect(() => {
    setFrenchStatus(frenchStatus);
  }, [frenchStatus]);

  useEffect(() => {
    setEnglishStatus(englishStatus);
  }, [englishStatus]);

  const { authorInfo, allAuthorsOptions, assignAuthorToArticle, removeAuthorFromArticle } = useContext(AuthorsContext);

  const { categoryStore } = useCategoriesData();

  const { categoryInfo, allCategoriesOptions } = categoryStore;
  const { categories } = categoryInfo;

  const [highlightCardOption, setHighlightCardOption] = useState(0);

  const [text, setText] = useState('');
  const [metaTextSuggestionActive, setMetaTextSuggestionActive] = useState(false);
  const [showSaveButtonInHeader, setShowSaveButtonInHeader] = useState(true);
  const [showSaveButtonTop, setShowSaveButtonTop] = useState(false);

  const { response, handleClick } = useOpenAI(text, metaTextSuggestionActive);
  const { highlightedText, setHighlightedText, isLoading } = useHighlight(handleClick);

  // const insights = useInsights(article.translations?.find((t) => t.locale === 'en').content)
  // console.log('insights', insights)

  useEffect(() => {
    if (highlightedText) {
      setText(highlightedText);
    }
  }, [highlightedText]);

  useScrollPosition(({ prevPos, currPos }) => {
    const isScrollingDown = currPos.y < prevPos.y;
    const showSaveButtonTop = currPos.y < -window.innerHeight * 0.01;

    if (isScrollingDown && showSaveButtonTop) {
      setShowSaveButtonTop(true);
      setShowSaveButtonInHeader(false);
    } else if (!isScrollingDown && !showSaveButtonTop) {
      setShowSaveButtonTop(false);
      setShowSaveButtonInHeader(true);
    } else if (!isScrollingDown && showSaveButtonTop) {
      setShowSaveButtonTop(true);
      setShowSaveButtonInHeader(false);
    }
  });

  return (
    <PageContainer>
      <Container>
        {previewModal ? (
          <>
            <MobilePreview languageArticle={languageArticle} initialValues={article.translations} />
          </>
        ) : (
          <>
            {article.translations && (
              <EditArticleForm
                initialValues={article.translations}
                id={id}
                formType="edit"
                languageArticle={languageArticle}
                showSaveButtonTop={showSaveButtonTop}
                showSaveButtonInHeader={showSaveButtonInHeader}
              />
            )}

            {metaTextSuggestionActive && (
              <MetaTextSuggestion
                highlightedText={highlightedText}
                response={response}
                isLoading={isLoading}
                highlightCardOption={highlightCardOption}
                setHighlightCardOption={setHighlightCardOption}
              />
            )}
          </>
        )}
      </Container>
      <RightPanelModal
        size
        padding="0"
        shouldShow={viewModal}
        onRequestClose={() => setViewModal(false)}
        children={
          <MetaPanel
            metaTextSuggestionActive={metaTextSuggestionActive}
            setMetaTextSuggestionActive={setMetaTextSuggestionActive}
            author={authorInfo}
            allAuthorsOptions={allAuthorsOptions}
            sources={sources}
            assignAuthorToArticle={assignAuthorToArticle}
            removeAuthorFromArticle={removeAuthorFromArticle}
            addSourceToArticle={addSourceToArticle}
            removeSourceToArticle={removeSourceToArticle}
            editSourceToArticle={editSourceToArticle}
            keywords={keywords}
            editKeywords={editKeywords}
            seoData={seo}
            addSeoToArticle={addSeoToArticle}
            article={article}
            updateArticleStatus={updateArticleStatus}
            frenchStatus={frenchStatus}
            englishStatus={englishStatus}
          />
        }
      />
    </PageContainer>
  );
};
