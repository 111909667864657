import Button from '../../elements/Button';
import { I } from '../../elements/I';

function MainButton({ children, label, onClick }) {
  return (
    <button onClick={onClick}>
      {label}
      {children}
    </button>
  );
}

export const TableActionButton = ({ label, iconClassName, onClick, color }) => {
  return (
    <>
      <MainButton onClick={onClick}>
        {label} <I className={iconClassName} color={color} />
      </MainButton>
    </>
  );
};
