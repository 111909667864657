import styled from 'styled-components';
import { H3 } from '../Categories';
import { Card as CardRef } from '../../../../../components/layouts/Card';

export const Layout = styled.div`
  border-radius: 0.4rem;
`;

export const Card = styled(CardRef)`
  gap: 0.9rem;
  box-shadow: unset;
  padding: 0rem;
`;

export const H4 = styled.h4`
  margin: 0;
  margin-right: auto;
  margin-bottom: 1rem;
`;

export const StyledListItem = styled.div`
  font-size: 1.3rem;
  margin-right: auto;
  margin: 2rem 0;
`;

export const ItemLabel = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

export const ListItem = ({ label, value }) => {
  return (
    <StyledListItem>
      <ItemLabel>{label}</ItemLabel> {value}
    </StyledListItem>
  );
};
