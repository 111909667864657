export const themeSettings = {
  colors: {
    primary: '#175CD3',
    primary2: '#0f3e8e',
    secondary: '#08a2e5',
    accent: '#FFC107',
    success: '#4CAF50',
    danger: '#F44336',
    warning: '#FF9800',
    info: '#2196F3',
    dark: '#212121',
    light: '#F5F5F5',
    text: {
      primary: '#212121',
      secondary: '#757575',
      disabled: '#BDBDBD',
      hint: '#9E9E9E',
    },
    background: {
      primary: '#FFFFFF',
      secondary: '#F5F5F5',
    },
    borders: {
      primary: '#E0E0E0',
      secondary: '#BDBDBD',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: {
      small: '14px',
      medium: '16px',
      large: '18px',
      extraLarge: '24px',
    },
    fontWeight: {
      normal: 400,
      medium: 500,
      bold: 700,
    },
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  zIndex: {
    low: 1,
    medium: 5,
    high: 10,
    overlay: 100,
    modal: 1000,
  },
};
