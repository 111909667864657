export default {
  fr: {
    FR: 'Publier en français',
    EN: "Publier pour l'anglais",
    BOTH: 'Publier en français et en anglais',
  },
  en: {
    FR: 'Publish for french',
    EN: 'Publish for english',
    BOTH: 'Publish for both french and english',
  },
};
