import React from 'react';
import { Container, Page } from './Style';
import ProductionStatistics from './ProductionStatistics';
export const ArticleStatistics = () => {
  return (
    <Page>
      <Container>
        <ProductionStatistics />
      </Container>
    </Page>
  );
};
