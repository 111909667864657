import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 10px;
`;

export const PageButton = styled.button`
  background-color: ${({ active, theme }) => (active ? theme.colors.primary : '#ffffff')};
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.colors.primary)};
  /* border: ${({ theme }) => `1px solid ${theme.colors.primary2}`}; */
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: ${({ active, theme }) => (active ? theme.colors.primary2 : '#ebebeb')};
    color: ${({ active, theme }) => (active ? '#ffffff' : theme.colors.primary2)};
  }

  &:first-of-type {
    border-radius: 2.5rem 0 0 2.5rem;
  }
  &:last-of-type {
    border-radius: 0 2.5rem 2.5rem 0;
    margin-right: 0;
  }
`;
