import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 8rem;
  background: #72a9b0;
  z-index: 1;

  &::before {
    /* content: '';
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 0; */
  }
`;
