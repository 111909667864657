import styled from 'styled-components';

export const MobilePreviewDiv = styled.div`
  width: 375px;
  height: 667px;
  align-items: center;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 10px;
  overflow: auto;
`;

export const MainImageArticle = styled.img`
  height: 240px !important;
  width: 100% !important;
  object-fit: cover;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 ${({ theme }) => theme.spacing.md} 0 ${({ theme }) => theme.spacing.md};
  iframe {
    width: 100%;
  }
`;

export const Table = styled.table`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  font-size: 14px;
  border-top: 1px solid #e8e8eb;
  tr {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    position: relative;
    border-bottom: 1px solid #e8e8eb;
  }
  td,
  th {
    border-right: 1px solid #e8e8eb;
    padding: 6px 12px;
    overflow: hidden;
    outline: none;
    line-break: normal;
  }
  td:first-child,
  th:first-child {
    border-left: 1px solid #e8e8eb;
  }
  th:last-child {
    border-right: 1px solid #e8e8eb;
  }
`;

export const H2SubTitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
`;

export const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
`;

export const H4 = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
`;

export const AttachmentIconContainer = styled.div`
  -webkit-mask-box-image: url(
      data:image/svg + xml,
      %3Csvgwidth='24'height='24'viewBox='0 0 24 24'fill='none'xmlns='http://www.w3.org/2000/svg'%3E%3Cpathd='M0 10.3872C0 1.83334 1.83334 0 10.3872 0H13.6128C22.1667 0 24 1.83334 24 10.3872V13.6128C24 22.1667 22.1667 24 13.6128 24H10.3872C1.83334 24 0 22.1667 0 13.6128V10.3872Z'fill='black'/%3E%3C/svg%3E%0A
    )
    48% 41% 37.9% 53.3%;
  background-color: #333;
  width: 27px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const AttachmentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M13.3236 8.43554L9.49533 12.1908C9.13119 12.5505 8.93118 13.043 8.9393 13.5598C8.94741 14.0767 9.163 14.5757 9.53862 14.947C9.91424 15.3182 10.4191 15.5314 10.9422 15.5397C11.4653 15.5479 11.9637 15.3504 12.3279 14.9908L16.1562 11.2355C16.8845 10.5161 17.2845 9.53123 17.2682 8.4975C17.252 7.46376 16.8208 6.46583 16.0696 5.72324C15.3184 4.98066 14.3086 4.55425 13.2624 4.53782C12.2162 4.52138 11.2193 4.91627 10.4911 5.63562L6.66277 9.39093C5.57035 10.4699 4.97032 11.9473 4.99467 13.4979C5.01903 15.0485 5.66578 16.5454 6.79264 17.6592C7.9195 18.7731 9.43417 19.4127 11.0034 19.4374C12.5727 19.462 14.068 18.8697 15.1604 17.7907L18.9887 14.0354"
    ></path>
  </svg>
);
