import { useContext, useState } from 'react';
import Toggle from '../../../../components/inputs/Toggle';

import { Categories } from './Categories';
import { Insights } from './Insights';
import { ToggleSwitch } from '../../../../components/inputs/ToggleSwitch';
import { TextEmphasis } from '../../../../components/elements/TextEmphasis';

import { Card, Layout, PanelContainer, SlideOutPanel } from './Style';
import { Authors } from './Authors';

import { MetaData } from './MetaData';
import { Sources } from './Sources';
import { AuthContext } from '../../../../app/context/AuthContext';
import { Status } from './Status';
import { Services } from './Services';

export const MetaPanel = ({
  metaTextSuggestionActive,
  setMetaTextSuggestionActive,
  author,
  allAuthorsOptions,
  assignAuthorToArticle,
  removeAuthorFromArticle,
  sources,
  addSourceToArticle,
  removeSourceToArticle,
  editSourceToArticle,
  keywords,
  editKeywords,
  seoData,
  addSeoToArticle,
  article,
  updateArticleStatus,
  frenchStatus,
  englishStatus,
}) => {
  const [panelView, setPanelView] = useState(0);

  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  const interfaceTexts = {
    en: {
      toggleOptions: ['General', 'Meta Data', 'Insights'],
      openAiTextSuggestions: 'Open AI text suggestions:',
    },
    fr: {
      toggleOptions: ['Général', 'Méta-données', 'Aperçus'],
      openAiTextSuggestions: 'Suggestions de texte Open AI :',
    },
  };

  const { toggleOptions, openAiTextSuggestions } = interfaceTexts[appLocale || 'en'];

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      // should close the metaPanel but Idk why it doesn't work yet
      alert('works');
    }
  };

  return (
    <Layout style={{ overflow: 'auto' }}>
      <Toggle options={toggleOptions} panelView={panelView} setPanelView={setPanelView} />
      <br />
      <PanelContainer panelView={panelView} className={`${panelView === 1 ? 'slide-out' : ''}`}>
        {panelView === 0 && (
          <>
            <Card gap="0">
              <Status
                article={article}
                updateArticleStatus={updateArticleStatus}
                englishStatus={englishStatus}
                frenchStatus={frenchStatus}
              />
            </Card>
            {/*<Card>*/}
            {/*  <TextEmphasis>{openAiTextSuggestions}</TextEmphasis>*/}
            {/*  <ToggleSwitch active={metaTextSuggestionActive} onClick={() => setMetaTextSuggestionActive(!metaTextSuggestionActive)} />*/}
            {/*</Card>*/}
            <Card>
              <Categories />
            </Card>
            <Card gap="0">
              <Authors />
            </Card>
            <Card gap="0">
              <Services />
            </Card>
          </>
        )}

        {panelView === 1 && (
          <SlideOutPanel>
            <Card>
              <MetaData
                keywordsData={keywords}
                editKeywords={editKeywords}
                seoData={seoData}
                addSeoToArticle={addSeoToArticle}
                article={article}
              />
            </Card>
            <Card>
              <Sources
                sourcesData={sources}
                addSourceToArticle={addSourceToArticle}
                removeSourceToArticle={removeSourceToArticle}
                editSourceToArticle={editSourceToArticle}
              />
            </Card>
          </SlideOutPanel>
        )}
        {/*{panelView === 2 && (*/}
        {/*  <SlideOutPanel>*/}
        {/*    <Card>*/}
        {/*      <Insights />*/}
        {/*    </Card>*/}
        {/*  </SlideOutPanel>*/}
        {/*)}*/}
      </PanelContainer>
    </Layout>
  );
};
