import styled from 'styled-components';
import SelectRef from 'react-select';
import { Row as RowRef } from '../../../../../../components/layouts/Row';

export const Row = styled(RowRef)`
  margin: 0;
  width: fit-content;
`;

export const Select = styled(SelectRef)`
  font-size: 1.3rem;
  color: grey;
  font-weight: 500;
  min-width: 20rem;
  max-width: 30rem;

  > div {
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
export const Selected = styled.span`
  margin-bottom: 14px;
  margin: 0.6rem 0;
  font-size: 10.5rem;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 2rem;
  width: fit-content;
  max-width: 28rem;
  padding: 1rem 1.6rem;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  z-index: 0;
`;
