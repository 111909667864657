import React, { useEffect } from 'react';
import { StyledModal, Button, P } from './Style';
import { useModal } from '../../../app/context/PopUpModalContext';
import { useLocation, useNavigate } from 'react-router-dom';

export const Modal = ({ hideButton, onClose }) => {
  const { modalContent, setModalContent } = useModal();
  const location = useLocation();

  useEffect(() => {
    setModalContent(null);
  }, [location]);

  if (!modalContent) return null;

  return (
    <StyledModal>
      <P>{modalContent}</P>
      {!hideButton && (
        <Button
          onClick={() => {
            setModalContent(null);
            if (typeof onClose === 'function') onClose();
          }}
        >
          Close
        </Button>
      )}
    </StyledModal>
  );
};
