import { useSearchParams } from 'react-router-dom';
import setIdToken from '../../utils/auth/setIdToken';
import setAuthToken from '../../utils/auth/setAuthToken';

export const CallbackPage = () => {
  const [searchParams] = useSearchParams();
  console.log('CallbackPage', searchParams.get('id_token'));

  setIdToken(searchParams.get('id_token'));
  setAuthToken(searchParams.get('access_token'));

  window.location.replace('/dashboard');

  return <>Callback</>;
};
