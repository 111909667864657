import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 24.5px;
  background-color: #fffcf5 !important;
  border: 1px solid #fec84b !important;
  border-radius: 12px !important;
  width: 100% !important;
  padding: 16px 24px;
  display: ${({ mobile }) => (mobile ? 'block' : 'flex')};
  gap: 12px;
  .warning-icon {
    margin-left: 3.5px;
  }
  h3,
  p {
    color: #b54708 !important;
    margin: 0px !important;
    font-size: 14px;
  }
  h3 {
    font-weight: 600 !important;
    display: 'inline-block' !important;
  }
  p {
    display: block;
    font-weight: 400 !important;
  }
  @media screen and (max-width: 975px) {
    display: block;
  }
`;

const TriangleExclamationIcon = ({ width, strokeColor }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 20 17"
    fill="none"
    stroke={strokeColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99975 6.49995V9.83328M9.99975 13.1666H10.0081M8.84585 2.24305L1.99178 14.0819C1.61161 14.7386 1.42153 15.0669 1.44962 15.3364C1.47412 15.5714 1.59727 15.785 1.7884 15.924C2.00753 16.0833 2.38691 16.0833 3.14568 16.0833H16.8538C17.6126 16.0833 17.992 16.0833 18.2111 15.924C18.4022 15.785 18.5254 15.5714 18.5499 15.3364C18.578 15.0669 18.3879 14.7386 18.0077 14.0819L11.1537 2.24305C10.7748 1.58875 10.5854 1.26159 10.3383 1.15172C10.1228 1.05587 9.87672 1.05587 9.66117 1.15172C9.41406 1.26159 9.22466 1.58875 8.84585 2.24305Z"
      stroke={strokeColor}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WarningAlert = ({ header, subheader, key, mobile }) => {
  return (
    <Container key={key} mobile={mobile}>
      <div className="warning-icon">
        <TriangleExclamationIcon width={20} strokeColor="#DC6803" fillColor="#DC6803" />
      </div>
      <div>
        {header && <h3>{header}</h3>}
        {subheader && <p>{subheader}</p>}
      </div>
    </Container>
  );
};
