import React, { useContext } from 'react';
import { AddButton, AddCategory, CategoryItem, CategoryList, DeleteButton, Row, Select } from './Style';
import { useCategoryManager } from './useCategoryManager';
import { AuthContext } from '../../../../../../app/context/AuthContext';

export const CategoryManager = () => {
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  const {
    categories,
    selectedCategory,
    availableCategoriesOptions,
    handleAddCategory,
    handleDeleteCategory,
    handleSelectCategory,
  } = useCategoryManager();

  const byRank = (a, b) => a.rank - b.rank;
  return (
    <>
      <CategoryList>
        {categories.sort(byRank).map((category) => (
          <Row key={category.categoryId}>
            {category.translations && category.translations[1] && (
              <CategoryItem>{category.translations[1].title}</CategoryItem>
            )}
            <DeleteButton onClick={() => handleDeleteCategory(category)}>
              <i className="fa-duotone fa-minus-circle"></i>
            </DeleteButton>
          </Row>
        ))}
      </CategoryList>
      <AddCategory>
        <Select
          value={selectedCategory}
          options={availableCategoriesOptions.map((option) => ({
            id: option.id,
            translations: option.translations,
            label: appLocale === 'en' ? option.translations[1]?.title : option.translations[0]?.title,
          }))}
          isClearable
          isSearchable
          onChange={handleSelectCategory}
          placeholder={appLocale === 'en' ? 'Select a category' : 'Sélectionner une catégorie'}
        />

        <AddButton selectedCategory={selectedCategory}>
          <i className="fa-duotone fa-circle-plus" onClick={handleAddCategory} />
        </AddButton>
      </AddCategory>
    </>
  );
};
