import React from 'react';
import Lottie from 'lottie-react';
import error from './error.json';

export const ErrorLottie = () => {
  return (
    <Lottie
      animationData={error}
      playbackSpeed={0.8}
      loop={false}
      style={{ margin: 'auto', width: '6rem', height: 'auto' }}
    />
  );
};
