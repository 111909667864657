import styled from 'styled-components';
import { PageContainer } from '../../../components/layouts/PageContainer';

export const Page = styled(PageContainer)`
  width: 90%;
  margin: auto;
  padding-top: 5rem;
`;

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
