export const buildArticleUrl = (locale, article) => {
  const slug = article?.slug;
  if (!slug) {
    return null;
  } else if (
    locale === 'en' ||
    (article?.publishStatus?.length === 1 && article?.publishStatus?.[0] === 'EN') ||
    article?.displayedTitleLang === 'en'
  ) {
    return `${process.env.REACT_APP_PUBLIC_SITE_EN_URL}/${slug}`;
  }
  return `${process.env.REACT_APP_PUBLIC_SITE_FR_URL}/${slug}`;
};
