import styled from 'styled-components';

// export const Layout = styled.div``

// export const SubHeading = styled.span`
//   font-size: 1.3rem;
//   font-weight: bold;
// `
export const TextInput = styled.input`
  /* width: 80%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px; */
  background: #f1f1f1;
  border: none;
  border-radius: 2rem;
  width: 100%;
  padding: 0.35rem 1rem;
  min-height: 3rem;
  color: black;
  font-size: 1.05rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: gray;
  }
`;

export const TitleInput = styled.input`
  background: #f1f1f1;
  border: none;
  border-radius: 2rem;
  width: 100%;
  padding: 0.35rem 1rem;
  min-height: 3rem;
  color: black;
  font-size: 1.05rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: gray;
  }
`;

export const DescriptionInput = styled.input`
  background: #f1f1f1;
  border: none;
  border-radius: 2rem;
  width: 100%;
  padding: 0.35rem 1rem;
  min-height: 3rem;
  color: black;
  font-size: 1.05rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: gray;
  }
`;

export const AltInput = styled.input`
  background: #f1f1f1;
  border: none;
  border-radius: 2rem;
  width: 100%;
  padding: 0.35rem 1rem;
  min-height: 3rem;
  color: black;
  font-size: 1.05rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: gray;
  }
`;

export const Layout = styled.div`
  width: 100%;
`;

export const EditButton = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  i {
    border-radius: 50%;
    border: 2px solid transparent;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  & > i:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const SubHeading = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;
export const KeywordRow = styled.div`
  width: 100%;
`;
export const InputRow = styled.div`
  width: 100%;
`;
