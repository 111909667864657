import React, { createContext, useState, useEffect } from 'react';
import { useArticleContext } from '../ArticleContext';
import ApiHttpClient from '../../../utils/api/api-http-client';

const AuthorsContext = createContext();

const AuthorsProvider = ({ children }) => {
  const [authors, setAuthors] = useState([]); // authors displayed in settings
  const [authorInfo, setAuthorInfo] = useState({ en: null, fr: null });

  const [allAuthorsOptions, setAllAuthorsOptions] = useState([]);
  const apiClient = new ApiHttpClient();

  const { currentArticle } = useArticleContext();
  const { id } = currentArticle || {};

  useEffect(() => {
    async function fetchAuthors() {
      const response = await apiClient.get('/authors');
      setAuthors(response.data);
      setAllAuthorsOptions(response.data);

      const author = response.data.find((a) => a.id === currentArticle?.author);

      const authorInfoUpdated = {};

      currentArticle?.translations.forEach((translation) => {
        const author = response.data.find((a) => a.id === translation.author);
        if (author) {
          authorInfoUpdated[translation.locale] = { name: author.name, authorId: author.id };
        }
      });

      setAuthorInfo(authorInfoUpdated);
    }
    fetchAuthors();
  }, [currentArticle]);

  const createAuthor = async (newAuthor) => {
    const response = await apiClient.post('/authors', newAuthor);
    setAuthors([...authors, response.data]);
  };

  const updateAuthor = async (updatedAuthor) => {
    const response = await apiClient.put(`/authors/${updatedAuthor.id}`, updatedAuthor);
    const updatedAuthors = authors.map((author) => (author.id === response.data.id ? response.data : author));
    setAuthors(updatedAuthors);
  };

  const deleteAuthor = async (id) => {
    if (window.confirm('Are you sure you want to delete this author?')) {
      try {
        await apiClient.delete(`/authors/${id}`);
        const filteredAuthors = authors.filter((author) => author.id !== id);
        setAuthors(filteredAuthors);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // below is for authors in articles

  const assignAuthorToArticle = async (author, language) => {
    try {
      const updatedArticle = { ...currentArticle };

      const translationToUpdate = updatedArticle.translations.find((translation) => translation.locale === language);

      if (translationToUpdate) {
        translationToUpdate.author = author.value;
      }

      const res = await apiClient.put(`/articles/${id}`, updatedArticle);
      const authorId = author.value;
      const selectedAuthor = allAuthorsOptions.find((a) => a.id === authorId);

      if (selectedAuthor) {
        const authorObject = {
          authorId: authorId,
          name: author.label,
        };

        // Update the authorInfo state for the specific language
        setAuthorInfo({
          ...authorInfo,
          [language]: authorObject,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeAuthorFromArticle = async (language) => {
    try {
      const updatedArticle = { ...currentArticle };

      // Find the translation with the provided language (locale)
      const translationToRemoveAuthor = updatedArticle.translations.find(
        (translation) => translation.locale === language,
      );

      // Set the author of the specific translation to null
      if (translationToRemoveAuthor) {
        translationToRemoveAuthor.author = null;
      }

      // Make the API call to update the article
      const res = await apiClient.put(`/articles/${id}`, updatedArticle);
      console.log('remove author:', res);

      // Update the local authorInfo state by removing the author information for the specific language
      setAuthorInfo((prevAuthorInfo) => ({
        ...prevAuthorInfo,
        [language]: null,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const contextValue = {
    authors,
    setAuthors,
    createAuthor,
    updateAuthor,
    deleteAuthor,
    authorInfo,
    setAuthorInfo,
    allAuthorsOptions,
    setAllAuthorsOptions,
    assignAuthorToArticle,
    removeAuthorFromArticle,
  };

  return <AuthorsContext.Provider value={contextValue}>{children}</AuthorsContext.Provider>;
};

export { AuthorsContext, AuthorsProvider };
