import { Indicator, StyledStatusIndicator } from './Style';
import { TextEmphasis } from '../TextEmphasis';

export const StatusIndicator = ({ name, status, author = '' }) => (
  <StyledStatusIndicator name={name}>
    <Indicator status={status} />{' '}
    {author && (
      <>
        <TextEmphasis>{author ? author : 'No one'}</TextEmphasis> is currently working on article.
      </>
    )}
  </StyledStatusIndicator>
);
