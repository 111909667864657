import styled from 'styled-components';
import { H1 as H1r } from '../../../components/elements/H1';

export const H1 = styled(H1r)`
  /* margin-left: 3.8rem; */
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  width: 80%;
  font-size: 4.8rem;
  font-weight: 600;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
`;

export const TitleInput = styled.input`
  font-size: 4.8rem;
  font-weight: 600;
  white-space: pre-wrap !important;
  width: 100%;
  overflow-wrap: break-word;
  border: none;
  background: #fafafa;
  border-radius: 1rem;
  padding: 0.5rem;
  width: 90%;
`;
