import React from 'react';
import { Link } from 'react-router-dom';
import { buildArticleUrl } from '../features/articleManagement/buildArticleUrl';

export const ArticleLink = ({ locale, translation }) => {
  if (!locale) return <></>;

  if (translation?.publishDate) {
    return (
      <Link to={buildArticleUrl(locale, translation)} target="_blank" style={{ marginLeft: '2rem' }}>
        <span style={{ fontSize: '1.5em', color: 'rgb(23, 92, 211)' }}>
          <i class="fa-solid fa-arrow-up-right-from-square"></i>
        </span>
      </Link>
    );
  }

  return <></>;
};
