import React, { useContext, useState } from 'react';
import { SourcesList, H3, Layout, AddSource, Separator } from './Style';
import { ListItem } from './ListItem';
import { AddButton } from '../Categories/CategoryManager/Style';
import { AuthContext } from '../../../../../app/context/AuthContext';
import { TextArea } from '../../../../../components/inputs/TextArea';
import Button from '../../../../../components/elements/Button';
import styled, { useTheme } from 'styled-components';

const StyledConfirmSourceBatch = styled.button`
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.success};
  font-size: ${({ theme }) => theme.typography.fontSize.small};
  &:disabled {
    cursor: not-allowed;
    color: #a6f4c5;
  }
`;

export const Sources = ({ sourcesData, addSourceToArticle, removeSourceToArticle, editSourceToArticle }) => {
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';
  const [titleEn, setTitleEn] = useState('');
  const [titleFr, setTitleFr] = useState('');
  const [url, setUrl] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [isBatchOpen, setIsBatchOpen] = useState(false);
  const [sourceBatch, setSourceBatch] = useState('');

  const theme = useTheme();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleTitleFrChange = (event) => {
    setTitleFr(event.target.value);
  };

  const handleTitleEnChange = (event) => {
    setTitleEn(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleDeleteSource = async (source) => {
    try {
      removeSourceToArticle(source);
    } catch (error) {
      console.error('Erreur lors de la suppression de la source :', error);
    }
  };

  const handleAddSourceBatch = async () => {
    const sources = sourceBatch.split('\n');
    let cleanedSources = [];
    sources.forEach((source) => {
      if (source.trim() != '') {
        cleanedSources.push({
          translations: [
            {
              language: 'fr',
              title: source,
            },
            {
              language: 'en',
              title: source,
            },
          ],
          url: source,
          public: true,
        });
      }
    });
    try {
      await addSourceToArticle(cleanedSources);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsBatchOpen(false);
      setSourceBatch('');
    }
  };

  const handleAddSource = async () => {
    console.log('Ajout de la source avec comme titre : ' + titleFr + ' ' + titleEn + ' et l url : ' + url);

    if (titleFr.trim() === '' || titleEn.trim() === '' || url.trim() === '') {
      alert('Veuillez remplir tous les champs.');
      return;
    }

    const newSource = {
      translations: [
        {
          language: 'fr',
          title: titleFr,
        },
        {
          language: 'en',
          title: titleEn,
        },
      ],
      url: url,
      public: isChecked,
    };

    try {
      addSourceToArticle(newSource);
      setTitleFr('');
      setTitleEn('');
      setUrl('');
      setIsCreating(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout de la source :", error);
    }
  };

  const interfaceTexts = {
    en: {
      sources: 'Sources',
      addSource: 'Add source',
      noSource: 'No source for this article.',
      private: 'private',
      frenchTitle: 'french title',
      englishTitle: 'english title',
      articleLink: 'article link',
    },
    fr: {
      sources: 'Sources',
      addSource: 'Ajouter une source',
      noSource: 'Aucune source pour cet article.',
      private: 'privé',
      frenchTitle: 'titre en français',
      englishTitle: 'titre en anglais',
      articleLink: "lien de l'article",
    },
  };

  const { sources, addSource, noSource } = interfaceTexts[appLocale || 'en'];

  return (
    <Layout>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: theme.spacing.md }}>
        <H3>{!isCreating ? sources : addSource}</H3>
        {!isCreating && (
          <AddButton style={{ marginLeft: theme.spacing.md }}>
            <i
              className="fa-duotone fa-circle-plus"
              onClick={() => {
                setIsBatchOpen(false);
                setIsCreating(true);
              }}
            />
          </AddButton>
        )}
        <Button
          disabled={isBatchOpen}
          onClick={() => {
            setIsCreating(false);
            setIsBatchOpen(true);
          }}
          style={{ marginLeft: 'auto' }}
        >
          {appLocale === 'en' ? 'Add in batch' : 'Ajouter en lot'}
        </Button>
      </div>
      {isBatchOpen && (
        <div style={{ marginBottom: theme.spacing.md, display: 'inline-block', width: '100%' }}>
          <TextArea
            onChange={(e) => setSourceBatch(e.target.value)}
            style={{ marginBottom: theme.spacing.md }}
            placeholder={
              appLocale === 'en' ? 'Paste row separated sources here' : 'Collez les sources séparées par ligne ici'
            }
          />
          <div style={{ display: 'flex', gap: theme.spacing.md, float: 'right' }}>
            <button
              onClick={() => {
                setIsBatchOpen(false);
                setSourceBatch('');
              }}
              style={{
                fontWeight: theme.typography.fontWeight.bold,
                cursor: 'pointer',
                color: theme.colors.danger,
                fontSize: theme.typography.fontSize.small,
              }}
            >
              {appLocale === 'en' ? 'Cancel' : 'Annuler'}
            </button>
            <StyledConfirmSourceBatch
              disabled={sourceBatch.trim() === ''}
              onClick={() => handleAddSourceBatch()}
              role="button"
            >
              {appLocale === 'en' ? 'Confirm' : 'Confirmer'}
            </StyledConfirmSourceBatch>
          </div>
        </div>
      )}
      {!sourcesData || sourcesData.length === 0 ? (
        <p style={{ fontSize: '1.5rem', color: 'grey', marginTop: 0 }}>{noSource}</p>
      ) : (
        !isCreating &&
        !isBatchOpen && (
          <>
            <SourcesList>
              {sourcesData.map((source, index) => (
                <React.Fragment key={source.url + '_' + index}>
                  <ListItem
                    sourceData={source}
                    sourcesData={sourcesData}
                    checked={source.public}
                    articlesSourceTitleFr={source.translations[0]?.title}
                    articlesSourceTitleEn={source.translations[1]?.title}
                    url={source.url}
                    handleDeleteSource={handleDeleteSource}
                    editSourceToArticle={editSourceToArticle}
                    interfaceTexts={interfaceTexts}
                    appLocale={appLocale}
                  />
                  {index !== sourcesData.length - 1 && <Separator />}
                </React.Fragment>
              ))}
            </SourcesList>
          </>
        )
      )}

      {isCreating && (
        <>
          {/* <H4>Add Source</H4> */}
          <AddSource
            newTitleFr={titleFr}
            newTitleEn={titleEn}
            url={url}
            isChecked={isChecked}
            handleTitleChangeFr={handleTitleFrChange}
            handleTitleChangeEn={handleTitleEnChange}
            handleUrlChange={handleUrlChange}
            handleAddSource={handleAddSource}
            handleCheckboxChange={handleCheckboxChange}
            interfaceTexts={interfaceTexts}
            appLocale={appLocale}
          />
        </>
      )}
    </Layout>
  );
};
