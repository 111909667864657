import { useContext } from 'react';
import { H3 } from '../Categories/Style';
import { AuthorManager } from './AuthorManager';
import { AuthContext } from '../../../../../app/context/AuthContext';

export const Authors = () => {
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';
  return (
    <>
      <H3>{appLocale === 'en' ? 'Authors' : 'Auteurs'}</H3>
      <AuthorManager />
    </>
  );
};
