/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';

import A from './A';
import StyledButton from './StyledButton';
import Wrapper from './Wrapper';
import { Link } from 'react-router-dom';

function Button(props) {
  const className = props.className;
  // Render an anchor tag
  let button = (
    <StyledButton className={className} onClick={props.onClick} type={props.type} {...props}>
      {Children.toArray(props.children)}
    </StyledButton>
  );

  if (props.to) {
    button = (
      <StyledButton>
        <Link to={props.to} href={props.href} className={className} onClick={props.onClick} {...props}>
          {Children.toArray(props.children)}
        </Link>
      </StyledButton>
    );
  }

  if (props.href) {
    button = (
      <StyledButton>
        <a href="https://www.eclecticenergies.com/enneagram/test" target="_blank" rel="noreferrer">
          {Children.toArray(props.children)}
        </a>
      </StyledButton>
    );
  }

  // return <Wrapper>{button}</Wrapper>
  return button;
}

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  // children: PropTypes.node.isRequired,
};

export default Button;
