import { useEffect, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import { EDITOR_JS_TOOLS } from '../../features/articleManagement/EditArticle/EditorJS_Tools/EditorJS_constants';

export const useEditor = ({ editorRef, contentTranslations, formType, languageArticle, setContentTranslations }) => {
  const [editor, setEditor] = useState(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isUpdatingContent, setIsUpdatingContent] = useState(false);
  const [previousLanguage, setPreviousLanguage] = useState(languageArticle);

  useEffect(() => {
    let newEditor = null;

    const initializeEditor = async () => {
      try {
        newEditor = await new EditorJS({
          holder: editorRef.current,
          tools: EDITOR_JS_TOOLS,
          data: {
            blocks: contentTranslations[languageArticle] || [],
          },
          onReady: () => {
            setEditor(newEditor);
            setIsEditorReady(true);
          },
        });
      } catch (error) {
        console.error('Error initializing editor:', error);
      }
    };

    initializeEditor();

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, []);

  const updateContent = async () => {
    setIsUpdatingContent(true);

    if (editor && isEditorReady && typeof editor.save === 'function') {
      const savedBlocks = await editor.save();

      setContentTranslations((prevState) => ({
        ...prevState,
        [previousLanguage]: savedBlocks.blocks || [],
      }));
      setIsUpdatingContent(false);

      if (Array.isArray(contentTranslations[languageArticle]) && contentTranslations[languageArticle].length === 0) {
        editor.render({
          blocks: [
            {
              type: 'paragraph',
              data: {
                text: '',
              },
            },
          ],
        });
      } else if (contentTranslations[languageArticle].length > 0) {
        editor.render({ blocks: contentTranslations[languageArticle] || [] });
      }
    }
  };

  useEffect(() => {
    if (editor && isEditorReady && previousLanguage !== null) {
      updateContent();
      // setPreviousLanguage(languageArticle)
    }
  }, [editor, isEditorReady, languageArticle]);

  useEffect(() => {
    if (editor && isEditorReady && languageArticle !== previousLanguage) {
      // Update the previousLanguage when languageArticle changes
      setPreviousLanguage(languageArticle);
    }
  }, [editor, isEditorReady, languageArticle]);

  return { editor, isEditorReady, refreshEditorContent: updateContent };
};
