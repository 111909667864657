import styled from 'styled-components';
import { H3 as H3b } from '../../../../../components/elements/H3';
import { Card as CardRef } from '../../../../../components/layouts/Card';
import { GridRow } from '../../../../../components/layouts/GridRow';
import React from 'react';
import { Row } from '../../../../../components/layouts/Row';
import { ToggleButton } from '../../../../../components/elements/ToggleButton';

export const Layout = styled.div`
  border-radius: 0.4rem;
  padding: 0rem 0;
  width: 100%;
`;

export const H3 = styled(H3b)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const SourcesInput = styled.input`
  background: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 2rem;
  width: 100%;
  padding: 0.6rem 1rem;
  /* min-height: 3rem; */
  color: white;
  font-size: 0.95rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-right: 1rem; */

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: white;
  }
`;

export const SourcesList = styled(CardRef)`
  box-shadow: unset;
  list-style: none;
  padding: 0;
  gap: 0.5rem 0;
  overflow-y: scroll;
  max-height: 18rem;
  justify-content: flex-start;
  margin-top: 1.8rem;

  &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        width: 1.4rem;
        border-radius: 0.7rem;
        background-clip: padding-box;
        border: 0.5rem solid transparent;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 0.5rem;
        color: rgba(0, 0, 0, 0);
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
        color: rgba(0, 0, 0, 0.3);
        }
    }
  }

  /* padding-top: 10rem; */
`;

export const StyledListItem = styled.div`
  display: flex;
  //align-items: center;
  font-size: 1.3rem;
  margin-right: auto;
  width: 100%;
`;

export const Checkbox = styled.input`
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  background-color: white;
  transition: ${({ theme }) => theme.transitionSpeed} all ease-in-out;
  cursor: pointer;
  margin: auto;

  &:checked {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    background-color: white;
  }
`;

export const H4 = styled.h4`
  margin: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

export const DeleteButton = styled.button`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 2.2rem;
  padding: 0;
  i {
    border-radius: 50%;
    border: 2px solid transparent;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  & > i:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const EditButton = styled.button`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 2.2rem;
  padding: 0;
  i {
    border-radius: 50%;
    border: 2px solid transparent;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  & > i:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TitleInput = styled.input`
  background: #f1f1f1;
  border: none;
  border-radius: 2rem;
  width: 100%;
  padding: 0.6rem 1rem;
  /* min-height: 3rem; */
  color: black;
  font-size: 0.95rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-right: 5px; */

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: gray;
  }
`;

const AddButton = styled.button`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 2.2rem;

  i {
    border-radius: 50%;
    border: 2px solid transparent;
  }

  align-items: center;
  display: flex;

  & > i:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Separator = styled.hr`
  width: 80%;
  border: none;
  border-top: 1px solid lightgrey;
`;

export const AddSource = ({
  newTitleFr,
  newTitleEn,
  url,
  isChecked,
  handleTitleChangeFr,
  handleTitleChangeEn,
  handleUrlChange,
  handleAddSource,
  handleCheckboxChange,
  interfaceTexts,
  appLocale,
}) => {
  const { sources, addSource, englishTitle, frenchTitle, articleLink } = interfaceTexts[appLocale || 'en'];

  return (
    <GridRow>
      <Row col="2" margin="0rem 0" justify="space-between">
        <ToggleButton items={['Private', 'Public']} />
      </Row>

      <GridRow col="3" colTemplate="5fr 5fr 1fr" gridGap="1rem" margin=" 0 0rem">
        <TitleInput
          value={newTitleEn}
          placeholder={appLocale === 'en' ? englishTitle : frenchTitle}
          onChange={handleTitleChangeEn}
        />
        <TitleInput
          value={newTitleFr}
          placeholder={appLocale === 'en' ? frenchTitle : englishTitle}
          onChange={handleTitleChangeFr}
        />
      </GridRow>

      <GridRow col="3" colTemplate="15fr 1fr" gridGap="1rem" margin=" 0 0rem">
        <SourcesInput value={url} placeholder={articleLink} onChange={handleUrlChange} />
        <EditButton onClick={handleAddSource}>
          <i className="fa-solid fa-check-circle"></i>
        </EditButton>
      </GridRow>
    </GridRow>
  );
};
