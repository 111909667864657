import styled from 'styled-components';

export const H1 = styled.h1`
  /* font-size: 1.9rem; */
  font-weight: 900;
  margin: 1.4rem 0;
  font-size: 2.6rem;
  color: ${({ theme }) => theme.colors.primary2};
  /* margin-left: 5rem; */
`;
