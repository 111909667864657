import { PageButton, PaginationWrapper } from './Style';

export const Pagination = ({ pageCount, currentPage, onPageChange }) => {
  const showPrevButton = currentPage > 1;
  const showNextButton = currentPage < pageCount;
  const pageButtons = [];

  const handlePageButtonClick = (pageIndex) => {
    onPageChange(pageIndex);
  };

  for (let i = 0; i < pageCount; i++) {
    if (i === 0 || i === pageCount - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
      pageButtons.push(
        <PageButton key={i} active={i === currentPage - 1} onClick={() => handlePageButtonClick(i + 1)}>
          {i + 1}
        </PageButton>,
      );
    } else if (pageButtons[pageButtons.length - 1] !== '...') {
      pageButtons.push('...');
    }
  }

  return (
    <PaginationWrapper>
      {showPrevButton && <PageButton onClick={() => handlePageButtonClick(currentPage - 1)}>{'<'}</PageButton>}
      {pageButtons}
      {showNextButton && <PageButton onClick={() => handlePageButtonClick(currentPage + 1)}>{'>'}</PageButton>}
    </PaginationWrapper>
  );
};
