import { H3 } from '../Categories/Style';
import { ServiceManager } from './ServiceManager';

export const Services = () => {
  return (
    <>
      <H3>Services</H3>
      <ServiceManager />
    </>
  );
};
