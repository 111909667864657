import styled from 'styled-components';
import { H3 as H3b } from '../../../../../components/elements/H3';
import { Card as CardRef } from '../../../../../components/layouts/Card';

export const Dropdown = styled.div`
  margin-top: 16px;

  .react-select-container {
    width: 200px;
  }
`;

export const Layout = styled.div`
  width: 100%;
`;

export const CategoryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  white-space: nowrap; /* Prevent line breaks */
  line-height: 3rem;
`;

export const SubcategoryRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  line-height: 2.6rem;
  box-shadow: 0px 3px 7px 4px rgba(225, 225, 225, 0.5);
  overflow-x: auto; /* Set horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  margin: 1rem;
  /* margin-left: -1rem; */
  z-index: 0;
  margin-right: 0;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    height: 0.4rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(192, 192, 192, 0);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: flex;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(192, 192, 192, 0.5);
      border-radius: 4px;
    }
  }

  .add-icon {
    display: none;
  }

  &:hover {
    .add-icon {
      display: block;
    }
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  position: relative;
`;

export const Category = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 2rem;
  width: fit-content;
  padding: 0rem 2rem;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  z-index: 1;
`;

export const EditIcon = styled.i`
  display: none;
  position: absolute;
  right: -1.5rem;
  cursor: pointer;
`;

export const EditableCategory = styled.input`
  background: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 2rem;
  width: fit-content;
  padding: 0rem 2rem;
  min-height: 3rem;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;

export const Subcategory = styled.div`
  margin: 0 2rem;

  color: #949494;
  font-size: 1.2rem;
`;

export const H3 = styled(H3b)`
  margin-top: 0rem;
  font-size: 1.8rem;
  letter-spacing: -0.25px;
`;

export const Card = styled(CardRef)`
  margin: 0;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;
`;
