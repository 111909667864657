// import styled from 'styled-components';

// export const ProfilePicture = styled.img`
//   width: 4.6rem;
//   height: 4.6rem;
//   object-fit: cover;
//   border-radius: 50%;
//   box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
//   background-color: lightGrey;
//   background-image: ${({ viewType }) =>
//     viewType ? `url(https://soumissionrenovation.ca${viewType})` : 'none'};
//      background-size: 180%;
//   background-repeat: no-repeat;
//   background-position: center;
// `;

import styled from 'styled-components';

const ProfileThumbnail = styled.div`
  width: 4.6rem;
  height: 4.6rem;
  object-fit: cover;
  border-radius: 50%;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  background-color: #fafafa;
  background-image: ${({ viewType }) => (viewType ? `url(https://soumissionrenovation.ca${viewType})` : 'none')};
  background-size: 180%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  i {
    font-size: 2.5rem;
    color: lightgrey;
  }
`;

export const ProfilePicture = ({ viewType, ...props }) => {
  return (
    <ProfileThumbnail viewType={viewType} {...props} onClick={props.onClick}>
      {viewType === null && <i className="fa fa-user" />}
    </ProfileThumbnail>
  );
};
