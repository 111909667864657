import styled from 'styled-components';

export const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 2rem 2rem 2rem 2rem;
  height: 100%;
  height: 2.2rem;
`;

export const Option = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.2rem;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease-in-out;

  span {
    z-index: 20;
    font-size: 0.9rem;
    margin: auto;
    color: white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    background: white;
    border-radius: 2rem;
    width: 2.8rem;
    height: 2.8rem;
    top: -0.4rem;
    left: -0.4rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary};

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
    }

    &:after {
      content: '';
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
      color: ${({ theme }) => theme.colors.primary};
      background: white;
      border-radius: 2rem;
      width: 130%;
      height: 1.6rem;
      transition: transform 0.2s ease-in-out;
    }
  }

  &:hover {
    color: white;

    &:before {
      opacity: 0.2;
    }
  }
`;
