import styled from 'styled-components';

export const ExpandableTriangle = styled.div`
  position: absolute;
  top: 6.5rem;
  right: 0rem;
  width: 3rem;
  height: 3rem;
  background: linear-gradient(to bottom right, transparent 50%, #0f3e8e 50%);
  cursor: pointer;
  transform: rotate(-90deg);
  transition: all 0.2s ease;

  &:hover {
    transform: scale(2) rotate(-90deg) translate(-0.75rem, -0rem);
    background: linear-gradient(to bottom right, transparent 50%, #175cd3 50%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
  }
`;
