import styled from 'styled-components';

export const Indicator = styled.span`
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  /* margin-right: 1.2rem; */
  background-color: ${(props) => (props.status === 'working' ? '#3DDFA5' : props.status === 'done' ? 'green' : 'red')};
  background-color: ${({ status, theme }) => (status === 'inactive' ? theme.colors.danger : theme.colors.success)};
`;

export const StyledStatusIndicator = styled.div`
  width: fit-content;
  font-size: 12px;
  margin: ${({ name }) => name === 'author' && '0'};
  margin: ${({ name }) => name !== 'author' && 'auto'};
  margin-left: auto;
`;
