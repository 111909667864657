import { useEffect, useState } from 'react';
import createHighVelocityContext from '../../../../utils/createHighVelocityContext';
import { useArticleStore } from '../ArticleDataProvider';
import ApiHttpClient from '../../../../utils/api/api-http-client';

export const { Provider: CategoriesProvider, useStore: useCategoriesStore } = createHighVelocityContext({
  categoryInfo: { categories: [] },
  allCategoriesOptions: [],
  loading: false,
});

export const useCategoriesData = () => {
  const [categoryStore, updateCategoryStore] = useCategoriesStore((store) => store);

  const [articleStore, updateArticleStore] = useArticleStore((store) => store);

  const [loading, setLoading] = useState(false);

  const apiClient = new ApiHttpClient();

  const article = articleStore.article;
  const id = articleStore.article.id;

  const getCategoriesOptions = async () => {
    setLoading(true);
    try {
      const categoriesResponse = await apiClient.get(`/categories`);
      const categoriesData = categoriesResponse.data;

      if (article.categories && categoriesData.length > 0) {
        const categoryAssociations = article.categories.reduce((associations, rankedCategory) => {
          const category = categoriesData.find((c) => c.id === rankedCategory.id);
          if (category) {
            const categoryObject = {
              categoryId: rankedCategory.id,
              translations: category.translations,
              rank: rankedCategory.rank,
            };

            if (!associations.some((assoc) => assoc.categoryId === rankedCategory.id)) {
              return [...associations, categoryObject];
            }
          }
          return associations;
        }, []);

        updateCategoryStore({
          ...categoryStore,
          allCategoriesOptions: categoriesData,
          categoryInfo: { categories: categoryAssociations },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesOptions();
  }, [id, article]);

  const addCategoryToArticle = async (category) => {
    let categoryMapped = {
      categoryId: category.id,
      translations: category.translations,
      rank: category.rank,
    };
    try {
      const updatedCategories = [...categoryStore.categoryInfo.categories, categoryMapped];

      const updatedArticle = {
        ...article,
        categories: updatedCategories.map((category, rank) => ({ id: category.categoryId, rank })),
      };

      const res = await apiClient.put(`/articles/${id}`, updatedArticle);
      updateCategoryStore({
        ...categoryStore,
        categoryInfo: {
          ...categoryStore.categoryInfo,
          categories: updatedCategories,
        },
      });

      updateArticleStore({ article: updatedArticle });
    } catch (error) {
      console.log(error);
    }
  };

  const removeCategoryFromArticle = async (category) => {
    try {
      const updatedCategories = article.categories.filter((cat) => cat.id !== category.categoryId);

      const updatedArticle = {
        ...article,
        categories: updatedCategories,
      };

      const res = await apiClient.put(`/articles/${id}`, updatedArticle);

      const updatedCategoryAssociations = updatedCategories.reduce((associations, rankedCategory) => {
        const category = categoryStore.allCategoriesOptions.find((c) => c.id === rankedCategory.id);
        if (category) {
          const categoryObject = {
            categoryId: rankedCategory.id,
            rank: rankedCategory.rank,
            translations: category.translations,
          };

          if (!associations.some((assoc) => assoc.categoryId === rankedCategory.id)) {
            associations.push(categoryObject);
          }
        }
        return associations;
      }, []);

      updateArticleStore({ article: updatedArticle });

      updateCategoryStore((store) => ({
        ...store,
        categoryInfo: {
          ...store.categoryInfo,
          categories: updatedCategoryAssociations,
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    // categoryInfo,
    categoryStore,
    updateCategoryStore,
    addCategoryToArticle,
    removeCategoryFromArticle,
    loading,
  };
};
