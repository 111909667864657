import Raw from '@editorjs/raw';

export default class Signature extends Raw {
  static get toolbox() {
    return {
      title: 'Signature',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 14H6V7h12v10z"/></svg>',
    };
  }
}
