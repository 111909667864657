import { useEffect } from 'react';
import Spinner from '../../../../components/elements/Loader';
import { HighlightCard, TotalWords } from '../EditArticleForm/Style';

export const MetaTextSuggestion = ({
  highlightedText,
  highlightCardOption,
  setHighlightCardOption,
  isLoading,
  response,
}) => {
  function countWords(text = '') {
    if (text !== null) {
      const words = text.split(/\b\S+\b/g);
      return words?.length;
    }
  }

  useEffect(() => {}, [response]);
  return (
    <>
      {highlightedText && (
        <>
          <HighlightCard>
            <div>
              {['Way different', 'More professional', 'More casual', 'half the words', 'in great depth'].map(
                (option, index) => (
                  <>
                    <span
                      key={index}
                      onClick={() => setHighlightCardOption(index)}
                      className={`options ${highlightCardOption === index ? 'selected' : ''}`}
                    >
                      {option}
                    </span>
                    <span className="separator">|</span>
                  </>
                ),
              )}
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <span style={{ marginTop: '1.3rem' }}>
                <span>
                  {(() => {
                    try {
                      console.log('response', response);
                      const data = JSON.parse(response);
                      return data[highlightCardOption];
                    } catch (error) {
                      return 'Highlight word or sentence to obtain variations';
                      // return 'Response is not valid JSON'
                    }
                  })()}
                </span>
              </span>
            )}
            <TotalWords>
              total words:
              {response !== '' && (
                <>
                  <span>{countWords(JSON?.parse(response)[highlightCardOption])}</span>
                </>
              )}
            </TotalWords>
          </HighlightCard>
        </>
      )}
    </>
  );
};
