import createHighVelocityContext from '../../../../utils/createHighVelocityContext';

export const { Provider: ProviderTest, useStore: useTestStore } = createHighVelocityContext({
  test: 'test123',
  object: {},
  array: [],
  function: () => 'test',
});

// export const createBearSlice = (set) => ({
//   bears: 0,
//   addBear: () => set((state) => ({ bears: state.bears + 1 })),
//   eatFish: () => set((state) => ({ fishes: state.fishes - 1 })),
// })

// example
{
  /* 
<TextInputTest value="test" />
*/
}

export const TextInputTest = ({ value }) => {
  const [fieldValue, setFieldValue] = useTestStore((store) => store[value]);
  const [store, setStore] = useTestStore((store) => store);

  console.log(store, setStore);

  return (
    <>
      <div>
        {value}: <input value={fieldValue} onChange={(e) => setFieldValue({ [value]: e.target.value })} />
      </div>
    </>
  );
};
