import styled, { css } from 'styled-components';

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  margin: ${(props) => props.margin || '2rem auto'};
  padding: ${(props) => props.padding};
  margin-left: ${(props) => props.marginLeft};
  margin-top: ${(props) => props.marginTop || ''};
  margin-bottom: ${(props) => props.marginBottom || ''};
  transform: ${(props) => props.transform};

  @media (max-width: 600px) {
    width: 100%;
    align-items: center;
  }
  /* 
  ${(props) => props.mobile && css``} */
`;
