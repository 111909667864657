import ApiHttpClient from '../../../utils/api/api-http-client';

export async function sendDataToServer({ locale, translations, titleTranslations, image, id, formType, categories }) {
  const apiClient = new ApiHttpClient();

  const translationData = Object.keys(translations).map((locale) => {
    return {
      locale,
      title: titleTranslations[locale],
      content: translations[locale],
      image: image,
    };
  });

  const requestBody = {
    translations: translationData,
    categories: categories?.map((category, rank) => ({
      id: category.categoryId,
      translations: category.translations,
      rank,
    })),
  };

  if (formType === 'create') {
    try {
      const response = await apiClient.post('/articles', requestBody);
      console.log('success response create ', response);
      return response;
    } catch (error) {
      console.log('create article error:', error);
    }
  } else if (formType === 'edit') {
    try {
      console.log('request body:', requestBody);
      const response = await apiClient.put(`/articles/${id}`, requestBody);
      console.log('success - response:', response);
      return { success: true, response };
    } catch (error) {
      return { success: false, errorMessage: error.message };
    }
  }
}
