import styled from 'styled-components';

export const Label = styled.div`
  margin-top: 1rem;
  position: relative;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  /* 
  &:before {
    position: absolute;
    bottom: 0%;
    content: '';
    width: 100%;
    height: 0.05rem;
    background: #b5b5b5;
  } */
  /* 
  span {
    position: absolute;
    bottom: -51%;
    text-transform: uppercase;
    font-size: 1.1rem;

    color: #b5b5b5;
    font-weight: bold;
    margin: 0;

    &:before {
      position: absolute;
      top: -30%;
      content: '';
      width: 100%;
      height: 0.17rem;
      background: #175cd3;
    }
  } */
`;
