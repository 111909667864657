import { useEffect, useState } from 'react';
import ApiHttpClient from '../../../utils/api/api-http-client';

export const useArticleData = (id) => {
  const [article, setArticle] = useState({});
  const [authorInfo, setAuthorInfo] = useState({});
  const [sources, setSources] = useState([]);
  const [seo, setSeo] = useState({});
  const [categoryInfo, setCategoryInfo] = useState([]);
  const [allCategoriesOptions, setAllCategoriesOptions] = useState([]);
  const [allAuthorsOptions, setAllAuthorsOptions] = useState([]);
  const [keywords, setKeywords] = useState('');
  const [englishStatus, setEnglishStatus] = useState();
  const [frenchStatus, setFrenchStatus] = useState();
  const [loading, setLoading] = useState(false);
  const apiClient = new ApiHttpClient();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [articleResponse, categoriesResponse, authorsResponse] = await Promise.all([
          apiClient.get(`/articles/${id}`),
          apiClient.get(`/categories`),
          apiClient.get(`/authors`),
        ]);

        const articleData = articleResponse.data;
        const authorData = articleResponse.data.author;
        const categoriesData = categoriesResponse.data;
        setArticle(articleData);
        setSources(articleData.sources ?? []);
        setSeo(articleData.seoTranslations);
        //2nd one is to be changed with seo keywords when I know where they come from from the backend
        setKeywords(articleData.keywords);
        setAllCategoriesOptions(categoriesData);
        setAllAuthorsOptions(authorsResponse.data);
        setFrenchStatus(articleData.translations[articleData.translations.findIndex((t) => t.locale === 'fr')].status);
        setEnglishStatus(articleData.translations[articleData.translations.findIndex((t) => t.locale === 'en')].status);
        const categoryAssociations = articleData.categories.reduce((associations, rankedCategory) => {
          const category = categoriesData.find((c) => c.id === rankedCategory.id);
          if (category) {
            const categoryObject = {
              categoryId: rankedCategory.id,
              translations: category.translations,
              rank: rankedCategory.rank,
            };

            if (!associations.some((assoc) => assoc.categoryId === rankedCategory.id)) {
              associations.push(categoryObject);
            }
          }
          return associations;
        }, []);

        setCategoryInfo(categoryAssociations);

        const authorId = articleData.author;
        const author = authorsResponse.data.find((a) => a.id === authorId);

        setAuthorInfo(author);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const addCategoryToArticle = async (category) => {
    try {
      const updatedCategories = [...article.categories, category.categoryId];

      const updatedArticle = {
        ...article,
        categories: updatedCategories,
      };

      await apiClient.put(`/articles/${id}`, updatedArticle);

      setCategoryInfo((prevCategoryInfo) => [...prevCategoryInfo, category]);
    } catch (error) {
      console.log(error);
    }
  };

  const assignServiceToArticle = async (serviceId) => {
    try {
      const updatedArticle = {
        ...article,
        service: serviceId,
      };

      await apiClient.put(`/articles/${id}`, updatedArticle);

      setArticle(updatedArticle);
    } catch (error) {
      console.log(error);
    }
  };

  const removeCategoryFromArticle = async (category) => {
    try {
      const updatedCategories = article.categories.filter((cat) => cat.id !== category.categoryId);

      const updatedArticle = {
        ...article,
        categories: updatedCategories,
      };

      const res = await apiClient.put(`/articles/${id}`, updatedArticle);

      const updatedCategoryAssociations = updatedCategories.reduce((associations, cat) => {
        const category = allCategoriesOptions.find((c) => c.id === cat.id);
        if (category) {
          const categoryObject = {
            categoryId: cat.id,
            rank: cat.rank,
            translations: category.translations,
          };

          if (!associations.some((assoc) => assoc.categoryId === cat.id)) {
            associations.push(categoryObject);
          }
        }
        return associations;
      }, []);

      setArticle(updatedArticle);
      setCategoryInfo(updatedCategoryAssociations);
    } catch (error) {
      console.log(error);
    }
  };

  const assignAuthorToArticle = async (author) => {
    try {
      const updatedArticle = {
        ...article,
        author: author.value,
      };

      const res = await apiClient.put(`/articles/${id}`, updatedArticle);

      const authorId = author.value;
      const selectedAuthor = allAuthorsOptions.find((a) => a.id === authorId);

      if (selectedAuthor) {
        const authorObject = {
          authorId: authorId,
          name: author.label,
        };

        setAuthorInfo(authorObject);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeAuthorFromArticle = async () => {
    try {
      const updatedArticle = {
        ...article,
        author: null,
      };

      const res = await apiClient.put(`/articles/${id}`, updatedArticle);
      console.log('remove author:', res);

      setAuthorInfo({});
    } catch (error) {
      console.log(error);
    }
  };

  const addSourceToArticle = async (source) => {
    try {
      const newSources = Array.isArray(source) ? [...sources, ...source] : [...sources, source];
      const response = await apiClient.put(`/articles/${id}`, { sources: newSources });
      setSources(response.data.sources);
    } catch (error) {
      console.log(error);
    }
  };

  const removeSourceToArticle = async (sourceToRemove) => {
    try {
      const newSources = sources.filter((source) => source !== sourceToRemove);
      const updatedSources = { sources: newSources };
      const response = await apiClient.put(`/articles/${id}`, updatedSources);
      setSources(response.data.sources);
    } catch (error) {
      console.log(error);
    }
  };

  const editSourceToArticle = async (updatedSource) => {
    try {
      const newSources = sources.map((source) => {
        if (source === updatedSource) {
          return updatedSource;
        }
        return source;
      });
      const updatedSources = { sources: newSources };
      const response = await apiClient.put(`/articles/${id}`, updatedSources);
      setSources(response.data.sources);
    } catch (error) {
      console.log(error);
    }
  };

  // const editKeywords = async (updatedKeywords) => {
  //   try {
  //     const response = await api.put(`/articles/${id}`, updatedKeywords)
  //     setKeywords(response.data.keywords)
  //   } catch (error) {}
  // }

  const editKeywords = async (updatedKeywords) => {
    try {
      const updatedArticle = {
        ...article,
        keywords: updatedKeywords,
      };
      const response = await apiClient.put(`/articles/${id}`, updatedArticle);
      setKeywords(response.data.keywords);
    } catch (error) {
      console.log(error);
    }
  };

  const addSeoToArticle = async (seo) => {
    try {
      const newSeo = { seoTranslations: seo };
      const response = await apiClient.put(`/articles/${id}`, newSeo);
      setSeo(response.data.seoTranslations);
    } catch (error) {
      console.log(error);
    }
  };

  const updateArticleStatus = async (status, lg) => {
    try {
      const updatedArticle = { ...article };
      const translationIndex = article.translations.findIndex((t) => t.locale === lg);
      updatedArticle.translations[translationIndex].status = status;
      await apiClient.put(`/articles/${id}`, updatedArticle);
      lg === 'en' ? setEnglishStatus(status) : setFrenchStatus(status);
    } catch (error) {
      console.log(error);
    }
  };

  const resetArticleStatus = async () => {
    try {
      const updatedArticle = { ...article };
      updatedArticle.translations.forEach((t) => {
        t.status = null;
      });
      await apiClient.put(`/articles/${id}`, updatedArticle);
      setEnglishStatus(null);
      setFrenchStatus(null);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    article,
    categoryInfo,
    authorInfo,
    allCategoriesOptions,
    allAuthorsOptions,
    addCategoryToArticle,
    removeCategoryFromArticle,
    assignServiceToArticle,
    assignAuthorToArticle,
    removeAuthorFromArticle,
    sources,
    addSourceToArticle,
    removeSourceToArticle,
    editSourceToArticle,
    addSeoToArticle,
    seo,
    keywords,
    editKeywords,
    loading,
    updateArticleStatus,
    resetArticleStatus,
    englishStatus,
    frenchStatus,
  };
};
