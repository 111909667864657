import styled, { css } from 'styled-components';
import SelectRef from 'react-select';
import { Row as RowRef } from '../../../../../../components/layouts/Row';
import { GridRow } from '../../../../../../components/layouts/GridRow';

export const Row = styled(RowRef)`
  margin: 0;
  width: fit-content;
`;

export const Select = styled(SelectRef)`
  font-size: 1.3rem;
  color: grey;
  font-weight: 500;
  min-width: 20rem;
  max-width: 30rem;

  > div {
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const CategoryList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 15rem;
  overflow-y: scroll;
`;

export const CategoryItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  margin: 0.6rem 0;
  font-size: 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 2rem;
  width: fit-content;
  max-width: 28rem;
  padding: 0.5rem 1.6rem;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  z-index: 0;
`;

export const DeleteButton = styled.button`
  /* margin-left: 8px; */
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  ${GridRow}:hover & {
    display: inline-block;
  }
`;

export const AddCategory = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;

export const AddButton = styled.button`
  /* width: fit-content; */
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 2.2rem;
  padding: 0;
  i {
    border-radius: 50%;
    border: 2px solid transparent;
  }
  ${({ selectedCategory }) =>
    selectedCategory !== null &&
    css`
      > i {
        transition: ${({ theme }) => theme.transitionSpeed} all ease-in-out;

        --fa-secondary-color: ${({ theme }) => theme.colors.primary};
        border: 2px solid ${({ theme }) => theme.colors.primary};
      }
    `}
`;
