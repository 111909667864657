import { useState } from 'react';
import { Option, StyledLanguageToggle } from './Style';

export const LanguageToggle = (
  { languages = ['fr', 'en'], languageArticle, setLanguageArticle, setInputValue },
  ...props
) => {
  const [selectedLanguage, setSelectedLanguage] = useState(languageArticle);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setLanguageArticle(language);
    // setInputValue('')
  };

  return (
    <StyledLanguageToggle {...props}>
      {languages.map((language) => (
        <Option
          text={language}
          key={language}
          className={selectedLanguage === language ? 'active' : ''}
          onClick={() => handleLanguageChange(language)}
        >
          <span>{language}</span>
        </Option>
      ))}
    </StyledLanguageToggle>
  );
};
