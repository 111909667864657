import React, { useState, useEffect, useContext } from 'react';
import { StackedBarChart } from '../../../../components/charts/StackedBarChart';
import styled, { useTheme } from 'styled-components';
import { Select } from '../../../articleManagement/EditArticle/MetaPanel/Categories/CategoryManager/Style';
import { AuthContext } from '../../../../app/context/AuthContext';
import ApiHttpClient from '../../../../utils/api/api-http-client';

const StyledCardContainer = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  padding: 24px;
  position: relative;
  border: 1px solid #eaecf0;
  background-color: white;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 5px 15px 0;
`;

const StyledInnerContainer = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  position: relative;
  min-height: 325px;
`;

export default function ProductionStatistics() {
  const theme = useTheme();
  const { appLocale } = useContext(AuthContext)?.user || {};
  const [productionStatistics, setProductionStatistics] = useState({
    statistics: [],
    meta: {
      year: new Date().getFullYear(),
      newArticles: 0,
      existingArticles: 0,
    },
  });
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState({
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  });

  const apiClient = new ApiHttpClient();

  const colors = {
    newArticles: '#ff4d64',
    existingArticles: '#dc171d',
  };

  const keys = ['newArticles', 'existingArticles'];

  const getMonthlyProductionStatistics = async () => {
    const endpoint = `/articles/monthly-production-statistics?year=${selectedYear.value}&lg=${appLocale}`;
    const response = await apiClient.get(endpoint);
    setProductionStatistics(response.data);
  };

  const getYearOptions = () => {
    const options = [];
    const currentYear = new Date().getFullYear();
    for (let x = currentYear - 2019; x > 0; x--) {
      const year = 2019 + x;
      options.push({
        value: year,
        label: year,
      });
    }
    setYearOptions(options);
  };

  useEffect(() => {
    getYearOptions();
  }, []);

  useEffect(() => {
    if (appLocale) {
      getMonthlyProductionStatistics();
    }
  }, [selectedYear, appLocale]);

  return (
    <StyledCardContainer>
      <div style={{ display: 'flex', marginBottom: '64px', alignItems: 'center' }}>
        <h2 style={{ color: '#646570', margin: 0 }}>
          {appLocale === 'en' ? 'Content production' : 'Production de contenus'}
        </h2>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <Select
            value={selectedYear}
            defaultValue={{ value: 2023, label: '2023' }}
            onChange={(e) => setSelectedYear(e)}
            options={yearOptions}
          />
        </div>
      </div>
      <StyledInnerContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <div>
              <div style={{ display: 'flex', gap: theme.spacing.md }}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingBottom: '3px' }}
                >
                  <div style={{ borderRadius: '50%', backgroundColor: '#ff4d64', height: '12px', width: '12px' }}></div>
                </div>
                <div>
                  <h3 style={{ marginBottom: 0, marginTop: 0, color: '#62636f', fontSize: '32px' }}>
                    {productionStatistics.meta.newArticles}
                  </h3>
                  <p style={{ fontSize: theme.typography.fontSize.medium, color: '#9b9b9b', margin: 0 }}>
                    {appLocale === 'en' ? 'New content' : 'Nouveaux contenus'}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: theme.spacing.md, marginTop: theme.spacing.xl }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingBottom: '3px' }}
              >
                <div style={{ borderRadius: '50%', backgroundColor: '#dc171d', height: '12px', width: '12px' }}></div>
              </div>
              <div>
                <h3 style={{ marginBottom: 0, marginTop: 0, color: '#62636f', fontSize: '32px' }}>
                  {productionStatistics.meta.existingArticles}
                </h3>
                <p style={{ fontSize: theme.typography.fontSize.medium, color: '#9b9b9b', margin: 0 }}>
                  {appLocale === 'en' ? 'Existing content' : 'Contenus existants'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'auto visible', display: 'flex', marginLeft: 'auto', paddingLeft: theme.spacing.xl }}>
          <StackedBarChart
            colors={colors}
            data={productionStatistics.statistics}
            keys={keys}
            stackOrder={'descending'}
          />
        </div>
      </StyledInnerContainer>
    </StyledCardContainer>
  );
}
