import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/elements/Button';
import { LanguageToggle } from '../../components/elements/LanguageToggle';
import { Header } from '../../components/layouts/Header';
import { CreateArticle } from '../../features/articleManagement/CreateArticle';

export const CreateArticlePage = () => {
  const [languageArticle, setLanguageArticle] = useState('fr');
  const [inputValue, setInputValue] = useState('');

  const navigate = useNavigate();
  return (
    <>
      <Header>
        <LanguageToggle
          languageArticle={languageArticle}
          setLanguageArticle={setLanguageArticle}
          setInputValue={setInputValue}
        />
        <Button onClick={() => navigate('/import-article')}>
          <i
            className="fa-solid fa-file-import"
            style={{ margin: 0, fontSize: '2rem', transform: 'translateX(-0.2rem)' }}
          ></i>
        </Button>
        {/* <StatusIndicator name='author' status='working' author={authorInfo?.name || 'No one'} /> */}
        {/* <ExpandableTriangle onClick={() => setViewModal(true)} /> */}
      </Header>
      <CreateArticle
        languageArticle={languageArticle}
        setLanguageArticle={setLanguageArticle}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
    </>
  );
};
