export const interfaceTexts = {
  en: {
    dashboard: 'Dashboard',
    articleTitle: 'Article title',
    author: 'Author',
    lastUpdate: 'Last update',
    status: 'Status',
    totalArticles: 'Total Articles',
    totalArticlesDescription: 'The total number of articles since the beginning.',
    weeklyArticles: 'Weekly Articles',
    weeklyArticlesDescription: 'The number of articles that have been worked on or updated in the last two weeks.',
    authors: 'Authors',
    authorsDescription: 'The total number of authors currently working on one or more articles.',
    publish: 'Published',
    frenchStatus: 'French status',
    englishStatus: 'English status',
    lastPublish: 'Last publish',
    actions: 'Actions',
  },
  es: {
    dashboard: 'Panel de control',
    articleTitle: 'Título del artículo',
    author: 'Autor',
    lastUpdate: 'Última actualización',
    status: 'Estado',
    totalArticles: 'Artículos Totales',
    totalArticlesDescription: 'El número total de artículos desde el inicio.',
    weeklyArticles: 'Artículos Semanales',
    weeklyArticlesDescription:
      'El número de artículos en los que se ha trabajado o actualizado en las últimas dos semanas.',
    authors: 'Autores',
    authorsDescription: 'El número total de autores que trabajan en uno o más artículos.',
    publish: 'Publicado',
    frenchStatus: 'Estatus francés',
    englishStatus: 'Estado ingles',
    lastPublish: 'Ultima publicación',
  },
  fr: {
    dashboard: 'Tableau de bord',
    articleTitle: "Titre de l'article",
    author: 'Auteur',
    lastUpdate: 'Dernière mise à jour',
    status: 'Statut',
    totalArticles: 'Articles totaux',
    totalArticlesDescription: "Le nombre total d'articles depuis le début.",
    weeklyArticles: 'Articles hebdomadaires',
    weeklyArticlesDescription:
      "Le nombre d'articles sur lesquels on a travaillé ou mis à jour au cours des deux dernières semaines.",
    authors: 'Auteurs',
    authorsDescription: "Le nombre total d'auteurs travaillant actuellement sur un ou plusieurs articles.",
    publish: 'Publié',
    frenchStatus: 'Statut français',
    englishStatus: 'Statut anglais',
    lastPublish: 'Dernière publication',
    actions: 'Actions',
  },
};
