import React, { useEffect, useState } from 'react';
import {
  Container,
  H2SubTitle,
  H3,
  H4,
  MainImageArticle,
  MobilePreviewDiv,
  Table,
  AttachmentIconContainer,
  AttachmentIcon,
} from './Style';
import { GridRow } from '../../../../components/layouts/GridRow';
import { H2 } from '../../../../components/elements/H2';
import * as PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { WarningAlert } from '../../../../components/alerts/WarningAlert';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';

function H6(props) {
  return null;
}

H6.propTypes = { dangerouslySetInnerHTML: PropTypes.shape({ __html: PropTypes.string }) };
export const MobilePreview = ({ initialValues, languageArticle }) => {
  const theme = useTheme();
  const [contentTranslations, setContentTranslations] = useState({
    fr: [],
    en: [],
  });
  const [image, setImage] = useState('');
  const [titleFr, setTitleFr] = useState('');
  const [titleEn, setTitleEn] = useState('');

  useEffect(() => {
    const frTranslations = initialValues[0].content.map((item) => ({
      ...item,
    }));

    const enTranslations = initialValues[1].content.map((item) => ({
      ...item,
    }));

    setContentTranslations({ fr: frTranslations, en: enTranslations });
    setImage(initialValues[0].image);
    setTitleFr(initialValues[0].title);
    setTitleEn(initialValues[1].title);
  }, [initialValues]);

  const processParagraphContent = (text) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(text, 'text/html');

    const images = parsedHtml.getElementsByTagName('img');

    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      const src = img.getAttribute('src');

      const modifiedSrc = 'https://soumissionrenovation.ca/' + src;
      img.setAttribute('src', modifiedSrc);
    }

    return parsedHtml.documentElement.innerHTML;
  };

  return (
    <MobilePreviewDiv>
      <GridRow col="1" style={{ marginTop: 0, marginBottom: '64px' }}>
        {image && <MainImageArticle src={`${image}`} />}
      </GridRow>
      <Container>
        <H2 style={{ marginTop: 0 }}>{languageArticle === 'fr' ? titleFr : titleEn}</H2>
        {contentTranslations[languageArticle].map((item, index) => {
          switch (item.type) {
            case 'paragraph':
              return <p key={index} dangerouslySetInnerHTML={{ __html: processParagraphContent(item.data.text) }} />;
            case 'header':
              switch (item.data.level) {
                case 2:
                  return (
                    <H2SubTitle
                      key={index}
                      dangerouslySetInnerHTML={{ __html: processParagraphContent(item.data.text) }}
                    />
                  );
                case 3:
                  return (
                    <H3 key={index} dangerouslySetInnerHTML={{ __html: processParagraphContent(item.data.text) }} />
                  );
                case 4:
                  return (
                    <H4 key={index} dangerouslySetInnerHTML={{ __html: processParagraphContent(item.data.text) }} />
                  );
              }
            case 'list':
              switch (item.data.style) {
                case 'unordered':
                  return (
                    <ul key={index}>
                      {item.data.items.map((listItem, listItemIndex) => (
                        <li
                          key={listItemIndex}
                          dangerouslySetInnerHTML={{ __html: processParagraphContent(listItem) }}
                        />
                      ))}
                    </ul>
                  );
                case 'ordered':
                  return (
                    <ol key={index}>
                      {item.data.items.map((listItem, listItemIndex) => (
                        <li
                          key={listItemIndex}
                          dangerouslySetInnerHTML={{ __html: processParagraphContent(listItem) }}
                        />
                      ))}
                    </ol>
                  );
                default:
                  return '';
              }
            case 'image':
              return <img key={index} src={item.data.file.url} alt={item.data.caption} style={{ width: '100%' }} />;
            case 'table':
              return (
                <Table key={index}>
                  {item.data.withHeadings && (
                    <thead>
                      <tr>
                        {item.data.content[0].map((tableHeader, tableHeaderIndex) => (
                          <th
                            key={tableHeaderIndex}
                            dangerouslySetInnerHTML={{ __html: processParagraphContent(tableHeader) }}
                          />
                        ))}
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {item.data.content.slice(item.data.withHeadings ? 1 : 0).map((tableRow, tableRowIndex) => (
                      <tr key={tableRowIndex}>
                        {tableRow.map((tableCell, tableCellIndex) => (
                          <td
                            key={tableCellIndex}
                            dangerouslySetInnerHTML={{ __html: processParagraphContent(tableCell) }}
                          />
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              );
            case 'quote':
              return (
                <blockquote key={index} style={{ margin: 0 }}>
                  <p style={{ backgroundColor: '#f2f4f7', padding: '12px', width: '100%', borderRadius: '12px' }}>
                    "{item.data.text}"
                  </p>
                  <footer>
                    <cite>—{item.data.caption}</cite>
                  </footer>
                </blockquote>
              );
            case 'delimiter':
              return <hr key={index} />;
            case 'warning':
              return <WarningAlert key={index} header={item.data.title} subheader={item.data.message} mobile />;
            case 'code':
              return <Highlight className="javascript">{item.data.code}</Highlight>;
            case 'checklist':
              return (
                <ul key={index} style={{ listStyleType: 'none', padding: 0 }}>
                  {item.data.items.map((listItem, listItemIndex) => (
                    <li key={listItemIndex} style={{ display: 'flex', alignItems: 'center' }}>
                      <input type="checkbox" checked={listItem.checked} style={{ marginRight: theme.spacing.sm }} />
                      <label>{listItem.text}</label>
                    </li>
                  ))}
                </ul>
              );
            case 'raw':
              return <div key={index} dangerouslySetInnerHTML={{ __html: item.data.html }} />;
            case 'attaches':
              return (
                <Link to={item.data.file.url} target="_blank">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px', cursor: 'pointer' }}>
                    <AttachmentIconContainer>
                      <AttachmentIcon />
                    </AttachmentIconContainer>
                    <span style={{ fontWeight: theme.typography.fontWeight.bold }}>{item.data.title}</span>
                  </div>
                </Link>
              );
            case 'blogCTA':
              return (
                <div key={index}>
                  <img src={item.data.imageUrl} width="100%" alt={item.data.title} />
                  <p>{item.data.title}</p>
                </div>
              );
            case 'newsletterCTA':
              return (
                <div key={index}>
                  <img src={item.data.imageUrl} width="100%" alt={item.data.title} />
                  <p>{item.data.paragraph}</p>
                </div>
              );
            case 'signature':
              return <div key={index} dangerouslySetInnerHTML={{ __html: item.data.html }} />;
            default:
              return '';
          }
        })}
      </Container>
    </MobilePreviewDiv>
  );
};
