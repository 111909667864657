import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6.4rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(203, 203, 203, 0.25);
  padding: 0 4rem;
`;
