import React, { useContext, useState, useEffect } from 'react';
import { H3 } from '../Categories/Style';
import { AuthContext } from '../../../../../app/context/AuthContext';
import { Select } from '../Categories/CategoryManager/Style';
import { useTheme } from 'styled-components';
import translationsStatus from '../../../../../translations/status';

export const Status = ({ article, updateArticleStatus, englishStatus, frenchStatus }) => {
  const { appLocale } = useContext(AuthContext)?.user || {};
  const [selectedStatusEnglish, setSelectedStatusEnglish] = useState();
  const [selectedStatusFrench, setSelectedStatusFrench] = useState();
  const theme = useTheme();

  const getOptions = () => {
    if (appLocale) {
      const options = [{ value: null, label: appLocale === 'en' ? 'Nothing to report' : 'Rien à signaler' }];

      for (const [key, value] of Object.entries(translationsStatus[appLocale])) {
        options.push({ value: key, label: value });
      }

      return options;
    }
    return [];
  };

  useEffect(() => {
    if (appLocale) {
      if (!englishStatus) {
        setSelectedStatusEnglish({ value: null, label: appLocale === 'en' ? 'Nothing to report' : 'Rien à signaler' });
      } else {
        setSelectedStatusEnglish({ value: englishStatus, label: translationsStatus[appLocale][englishStatus] });
      }
      if (!frenchStatus) {
        setSelectedStatusFrench({ value: null, label: appLocale === 'en' ? 'Nothing to report' : 'Rien à signaler' });
      } else {
        setSelectedStatusFrench({ value: frenchStatus, label: translationsStatus[appLocale][frenchStatus] });
      }
    }
  }, [frenchStatus, englishStatus]);

  return (
    <>
      <H3>{appLocale === 'en' ? 'Status' : 'Statut'}</H3>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', gap: theme.spacing.lg }}>
        <div>
          <div style={{ marginBottom: theme.spacing.md }}>
            <small>
              <b>{appLocale === 'en' ? 'French status' : 'Statut français'}</b>
            </small>
          </div>
          <Select
            value={selectedStatusFrench}
            options={getOptions()}
            onChange={(e) => {
              setSelectedStatusFrench(e);
              updateArticleStatus(e.value, 'fr');
            }}
            placeholder={appLocale === 'en' ? 'Select a status' : 'Sélectionner un statut'}
            menuPlacement="auto"
          />
        </div>
        <div>
          <div style={{ marginBottom: theme.spacing.md }}>
            <small>
              <b>{appLocale === 'en' ? 'English status' : 'Statut anglais'}</b>
            </small>
          </div>
          <Select
            value={selectedStatusEnglish}
            options={getOptions()}
            onChange={(e) => {
              setSelectedStatusEnglish(e);
              updateArticleStatus(e.value, 'en');
            }}
            placeholder={appLocale === 'en' ? 'Select a status' : 'Sélectionner un statut'}
            menuPlacement="auto"
          />
        </div>
      </div>
    </>
  );
};
