import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spinner = () => (
  <SpinnerWrapper>
    <Gear1 />
    <Gear2 />
  </SpinnerWrapper>
);

const gearRotation = keyframes`
  0% { transform: rotate(0deg);opacity:1;border-width: 4px; }
  52% {opacity:1; }
  83% {opacity:1;
    border-width: 3px; }

  100% { transform: rotate(360deg);opacity:1;
  border-width: 4px; }
`;
/* 
const gearRotation = keyframes`
 
      0% { transform: rotate3d(0, 1, 0.5, 0rad);
    border-color: #175CD3;
    border-width: 10px;

   }
  30% { transform: rotate3d(0, 1, 0.5, -1.71rad); border-color: #d2d2d2;height: 43px;border-width: 3px;}
  
  70% {
     transform: rotate3d(2, -1, -1, 2.142rad);border-color: #d2d2d2;
     width:43px;
     border-width: 10px;
      }
  90% {
     transform: rotate3d(2, -1, -1, 1.71rad);    border-color:#fafafa;
     width: 43px;height: 43px;
     border-width: 30px;
      }
` */

const gearRotation2 = keyframes`
  /* 0% { transform: rotate3d(0, 1, 0.5, 3.142rad);
    border-color: white;
   }
  30% { transform: rotate3d(0, 1, 0.5, 3.142rad); border-color: #d2d2d2; }
  70% {
     transform: rotate3d(2, -1, -1, 0turn);border-color: #d2d2d2;
     
      }
  90% {
     transform: rotate3d(2, -1, -1, .6turn);    border-color:#175CD3;
     
      } */
      0% { transform: rotate3d(0, 1, 0.5, 0rad);
    border-color: #175CD3;
    border-width: 10px;
    opacity:0;
/* width:10px; */
   }
  30% { transform: rotate3d(0, 1, 0.5, 1.71rad);
    border-color: #d2d2d2;
    height: 18px;
    border-width: 4px;
    opacity:0;
  }
    
  
  70% {
     transform: rotate3d(2, -1, -1, 2.142rad);border-color: #d2d2d2;
     width:23px;
     border-width: 5px;
     opacity:1;
      }
  90% {
     transform: rotate3d(2, -1, -1, 1.71rad);    border-color:#fafafa;
     width: 17px;height: 18px;
     border-width: 5px;
     opacity:0;
      }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin: 2rem 0;
`;

const Gear = styled.div`
  width: 3rem;
  height: 3rem;
  /* width: 100%;
  height: 100%; */
  border-radius: 50%;
  position: absolute;
`;

const Gear1 = styled(Gear)`
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-top-color: #d2d2d2;
  animation: ${gearRotation} 2s linear infinite;
`;

const Gear2 = styled(Gear)`
  border: 3px solid #979797;
  border-bottom-color: #d2d2d2;
  animation-direction: reverse;
  animation: ${gearRotation2} 4s linear infinite;
`;

export default Spinner;
