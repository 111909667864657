import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

/* primary font */

/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */



  *{
    box-sizing:border-box;
    -webkit-box-sizing: border-box;
  }

  html,
  body{
    scroll-behaviour: smooth;
    height: 100%;
    width: 100%;
    font-size: 62.5%;
    /* line-height: 1.5; */
    margin: 0;
    padding: 0;
    font-family: inherit;

 
  }

  &::-webkit-scrollbar {
    height: 0.4rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(192, 192, 192, 0.5);
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: flex;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
    }
  }

  h1,h2,h3,h4{
    font-family: 'Inter', Helvetica, Arial, sans-serif;
  }

  body {
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6em;
  }


/* // style resets */

  input{
    font-family:inherit;
  }

  button{
    border:0;
    background:transparent;
  }

  a{
    text-decoration:none;
    color: unset;
    margin:0;
  }


  // remove blue border focus
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }
  
  .cdx-checklist__item{
    align-items: center;
  }
  .tc-cell:first-child{
    border-left: 1px solid var(--color-border);
  }

  .image-tool__image-picture {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
`;

export default GlobalStyle;
