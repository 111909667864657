import axios from 'axios';
// import { stringify } from 'querystring';

export default class HttpClient {
  baseUrl = null;

  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(url, data, headers) {
    let fullUrl = this.formatUrl(url);

    headers = this.addAuthorizationToken(headers);

    // if (data) {
    //   fullUrl = `${fullUrl}?${stringify(data)}`;
    // }

    return await axios.get(fullUrl, { headers });
  }

  async post(url, data = {}, headers = {}) {
    let fullUrl = this.formatUrl(url);

    headers = this.addAuthorizationToken(headers);

    return await axios.post(fullUrl, data, { headers });
  }

  async put(url, data = {}, headers = {}) {
    let fullUrl = this.formatUrl(url);

    headers = this.addAuthorizationToken(headers);

    return await axios.put(fullUrl, data, { headers });
  }

  async delete(url, headers = {}) {
    let fullUrl = this.formatUrl(url);

    headers = this.addAuthorizationToken(headers);

    return await axios.delete(fullUrl, { headers });
  }

  formatUrl(url) {
    return `${this.baseUrl}${url}`;
  }

  addAuthorizationToken(headers) {
    const token = localStorage.getItem('token');
    if (token) {
      return {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
  }
}
